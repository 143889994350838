import {
  addVendorAPI,
  deleteVendorAPI,
  getVendorsAPI,
  getVendorAPI,
  updateVendorAPI,
  updateVendorStatusAPI,
} from "./Vendors.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class VendorsService extends BaseService {
  static getVendors(params) {
    return Client.get(getVendorsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getVendor(vendorId) {
    return Client.get(getVendorAPI(vendorId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addVendor(vendorData) {
    return Client.post(addVendorAPI, vendorData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static updateVendors(vendorId, vendorData) {
    return Client.put(updateVendorAPI(vendorId), vendorData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static updateVendorsStatus(vendorId, newStatus) {
    return Client.post(updateVendorStatusAPI(vendorId), {
      is_active: newStatus,
    }).then((res) => Promise.resolve(res));
  }
  static deleteVendors(vendorId) {
    return Client.delete(deleteVendorAPI(vendorId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
