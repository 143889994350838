<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <UserDetails :user="user"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditUserButton
            :item="user"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteUserButton
            :item="user"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useUsersStore } from "../store/UsersStore";
import UserDetails from "../components/UserDetails.vue";
import EditUserButton from "../components/action_buttons/EditUserButton.vue";
import DeleteUserButton from "../components/action_buttons/DeleteUserButton.vue";

export default {
  mounted() {
    var userId = this.$route.params.id;
    this.fetchUser(userId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useUsersStore, ["user"]),
  },
  methods: {
    ...mapActions(useUsersStore, ["fetchUser"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },
        {
          text: "Users",
          href: "/users",
          disabled: false,
        },
        {
          text: `User #${this.$route.params.id}`,
          disabled: true,
        },
      ],
    };
  },
  components: {
    UserDetails,
    EditUserButton,
    DeleteUserButton,
  },
};
</script>
../store/UsersStore
