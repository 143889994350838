<template>
  <BaseProductCategoryForm
    :productCategoryObject="productCategory"
    formActionName="create product category"
    @productCategorySubmitted="
      (categoryData) => sendProductCategoryData(categoryData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useProductCategoriesStore } from "../../store/ProductCategoriesStore";

import ProductCategory from "../../models/ProductCategory";
import BaseProductCategoryForm from "./BaseProductCategoryForm.vue";

export default {
  data() {
    return {
      productCategory: new ProductCategory(),
    };
  },
  methods: {
    ...mapActions(useProductCategoriesStore, ["createProductCategory"]),
    sendProductCategoryData(categoryData) {
      this.createProductCategory(categoryData)
        .then(() => {
          this.$router.push({ name: "product_categories" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseProductCategoryForm },
};
</script>

<style scoped></style>