export const getCommissionsAPI='/commissions';
export function getCommissionAPI(bannerId) {
  return "/commissions/" + bannerId ;
}
export const addCommissionAPI='/commissions';
export function updateCommissionAPI(bannerId) {
  return "/commissions/" + bannerId ;
}
export function deleteCommissionAPI(bannerId) {
  return "/commissions/" + bannerId ;
}