<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <ProductSubCategoryDetails :productSubCategory="productSubCategory"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditProductSubCategoryButton
            :item="productSubCategory"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteProductSubCategoryButton
            :item="productSubCategory"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useProductSubCategoriesStore } from "../store/ProductSubCategoriesStore";
import ProductSubCategoryDetails from "../components/ProductSubCategoryDetails.vue";
import EditProductSubCategoryButton from "../components/action_buttons/EditProductSubCategoryButton.vue";
import DeleteProductSubCategoryButton from "../components/action_buttons/DeleteProductSubCategoryButton.vue";

export default {
  mounted() {
    var productId = this.$route.params.id;
    this.fetchProductSubCategory(productId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useProductSubCategoriesStore, ["productSubCategory"]),
  },
  methods: {
    ...mapActions(useProductSubCategoriesStore, ["fetchProductSubCategory"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },
        {
          text: "Product subcategories",
          href: "/product-subcategories",
          disabled: false,
        },
        {
          text: `Product subcategory #${this.$route.params.id}`,
          disabled: true,
        },
      ],
    };
  },
  components: {
    ProductSubCategoryDetails,
    EditProductSubCategoryButton,
    DeleteProductSubCategoryButton,
  },
};
</script>