export default class Mapper {
  static underscoreToCamel(obj) {
    return JSON.parse(
      JSON.stringify(obj).replace(
        /(_\w)\w+":/g,
        match => match[1].toUpperCase() + match.substring(2)
      )
    );
  }
  static camelToUnderscore(obj) {
    let newObj = {};
    for (let camel in obj) {
      newObj[camel.replace(/([A-Z])/g, "_$1").toLowerCase()] = obj[camel];
    }
    return newObj;
  }
}
