<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> Edit banner category  </template>
      <v-container>
        <v-row>
          <v-col v-if="!fetchingData" cols="12">
            <EditBannerCategoryForm :bannerCategory="bannerCategory" />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState, mapActions } from "pinia";
  import { useBannerCategoriesStore } from "../store/BannerCategoriesStore";
  import EditBannerCategoryForm from "../components/forms/EditBannerCategoryForm.vue";
  
  export default {
    mounted() {
      var bannerCategoryId = this.$route.params.id;
      this.fetchBannerCategory(bannerCategoryId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useBannerCategoriesStore, ["bannerCategory"]),
    },
    methods: {
      ...mapActions(useBannerCategoriesStore, ["fetchBannerCategory"]),
    },
    data() {
      return {
        fetchingData:true,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },
          {
            text: "Banner categories",
            href: "/banner-categories",
            disabled: false,
          },
          {
            text: `Edit banner category #${this.$route.params.id}`,
            disabled: true,
          },
        ],
      };
    },
    components: { EditBannerCategoryForm },
  };
  </script>
  