export const getProductSubCategoriesAPI='/product_sub_categories';
export function getProductsSubCategoryAPI(subCategoryId) {
  return "/product_sub_categories/" + subCategoryId ;
}
export const addProductSubCategoryAPI='/product_sub_categories';
export function updateProductsSubCategoryAPI(subCategoryId) {
  return "/product_sub_categories/" + subCategoryId ;
}
export function updateProductsSubCategoryStatusAPI(subCategoryId) {
  return "/product_sub_categories/update-status/" + subCategoryId ;
}
export function deleteProductsSubCategoryAPI(subCategoryId) {
  return "/product_sub_categories/" + subCategoryId ;
}