import {
  addAmountToWalletAPI,
  getWalletAPI,
  getWalletsAPI,
} from "./Wallets.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class WalletsService extends BaseService {
  static getWallets(params) {
    return Client.get(getWalletsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getWallet(walletId) {
    return Client.get(getWalletAPI(walletId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addAmountToWallet(walletId, amount) {
    return Client.put(addAmountToWalletAPI(walletId), { amount: amount }).then(
      (res) => Promise.resolve(res)
    );
  }
}
