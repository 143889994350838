<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> Edit vendor </template>
      <v-container>
        <v-row>
          <v-col v-if="!fetchingData" cols="12">
            <EditVendorForm :vendor="vendor" />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState, mapActions } from "pinia";
  import { useVendorsStore } from "../store/VendorsStore";
  import EditVendorForm from "../components/forms/EditVendorForm.vue";
  
  export default {
    mounted() {
      var vendorId = this.$route.params.id;
      this.fetchVendor(vendorId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useVendorsStore, ["vendor"]),
    },
    methods: {
      ...mapActions(useVendorsStore, ["fetchVendor"]),
    },
    data() {
      return {
        fetchingData:true,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },
          {
            text: "Vendors",
            href: "/vendors",
            disabled: false,
          },
          {
            text: `Edit vendor #${this.$route.params.id}`,
            disabled: true,
          },
        ],
      };
    },
    components: { EditVendorForm },
  };
  </script>
