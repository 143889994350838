<template>
  <BaseAttributeForm
    :attributeObject="attribute"
    formActionName="create attribute"
    @attributeSubmitted="
      (attributeData) => sendAttributeData(attributeData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useAttributesStore } from "../../store/AttributesStore";

import Attribute from "../../models/Attribute";
import BaseAttributeForm from "./BaseAttributeForm.vue";

export default {
  data() {
    return {
      attribute: new Attribute(),
    };
  },
  methods: {
    ...mapActions(useAttributesStore, ["createAttribute"]),
    sendAttributeData(attributeData) {
      this.createAttribute(attributeData)
        .then(() => {
          this.$router.push({ name: "attributes" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseAttributeForm },
};
</script>

<style scoped></style>