<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>
        Attributes
      </template>
      <template v-slot:tableActions> <CreateAttributeButton /> </template>
      <template v-slot:dataTable>
        <AttributesTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import AttributesTable from "../components/AttributesTable.vue";
  import CreateAttributeButton from "../components/action_buttons/CreateAttributeButton.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },

          {
            text: "Attributes",
            disabled: true,
          },
        ],
      };
    },
    components: { AttributesTable,CreateAttributeButton },
  };
  </script>
  
  <style></style>
  