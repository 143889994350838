export default class Commission {
  id;
  name;
  commission_value;
  created_at;
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.commission_value=data?.commission_value;
    this.created_at = data?.created_at;
  }
}
