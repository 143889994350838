<template>
  <BaseCommissionForm
    :commissionObject="commission"
    formActionName="create commission"
    @commissionSubmitted="
      (commissionData) => sendCommissionData(commissionData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useCommissionsStore } from "../../store/CommissionsStore";

import Commission from "../../models/Commission";
import BaseCommissionForm from "./BaseCommissionForm.vue";

export default {
  data() {
    return {
      commission: new Commission(),
    };
  },
  methods: {
    ...mapActions(useCommissionsStore, ["createCommission"]),
    sendCommissionData(commissionData) {
      this.createCommission(commissionData)
        .then(() => {
          this.$router.push({ name: "commissions" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseCommissionForm },
};
</script>

<style scoped></style>