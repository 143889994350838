import ProductCategory from "@/modules/products_categories/models/ProductCategory";
import Product from "@/modules/products/models/Product";

export default class ProductSubCategory {
  id;
  name;
  is_active;
  created_at;
  category = new ProductCategory();
  product_category_id;
  products = [];
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.is_active = data?.is_active;
    this.product_category_id = data?.product_category_id;
    data?.products?.map((product) => {
      this.products.push(new Product(product));
    });
    this.category = new ProductCategory(data?.product_category);
    this.created_at = data?.created_at;
  }
}
