import { render, staticRenderFns } from "./BannerCategoryDetails.vue?vue&type=template&id=a2a8c69a&"
import script from "./BannerCategoryDetails.vue?vue&type=script&lang=js&"
export * from "./BannerCategoryDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VCardSubtitle,VCheckbox,VIcon,VImg,VRow,VScaleTransition,VSlideGroup,VSlideItem})
