<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> Edit product </template>
      <v-container>
        <v-row>
          <v-col v-if="!fetchingData" cols="12">
            <EditProductForm :product="product" />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState, mapActions } from "pinia";
  import { useProductsStore } from "../store/ProductsStore";
  import EditProductForm from "../components/forms/EditProductForm.vue";
  
  export default {
    mounted() {
      var productId = this.$route.params.id;
      this.fetchProduct(productId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useProductsStore, ["product"]),
    },
    methods: {
      ...mapActions(useProductsStore, ["fetchProduct"]),
    },
    data() {
      return {
        fetchingData:true,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },
          {
            text: "Products",
            href: "/products",
            disabled: false,
          },
          {
            text: `Edit product #${this.$route.params.id}`,
            disabled: true,
          },
        ],
      };
    },
    components: { EditProductForm },
  };
  </script>
../store/ProductsStore