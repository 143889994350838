<template>
  <v-text-field v-on="$listeners" v-bind="$attrs" outlined>
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
      ><slot :name="name" v-bind="slotData"></slot></template
  ></v-text-field>
</template>

<script>
export default {
  name: "BaseTextField",
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
</style>
