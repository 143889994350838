<template>
  <v-container class="all-vh primary" fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="5" sm="10">
        <v-card rounded="xl" class="pa-3 py-5 elevation-15">
          <v-img contain src="@/assets/logos/logo.png" max-height="200"></v-img>
          <h2 class="primary--text text--darken-1 text-center my-5">
             Hadyati Mall Dashboard
          </h2>
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col align="center" md="9" cols="12">
                  <v-form ref="form">
                    <BaseTextField
                      label="Email"
                      placeholder="Enter your Email"
                      persistent-placeholder
                      prepend-inner-icon="mdi-email"
                      type="email"
                      v-model="email"
                      :rules="[requiredRule, emailRule]"
                      @keyup.enter="postLogin()"
                    >
                    </BaseTextField>
                    <BasePasswordField
                      label="Password"
                      placeholder="Enter your password"
                      persistent-placeholder
                      prepend-inner-icon="mdi-key"
                      v-model="password"
                      @keyup.enter="postLogin()"
                      :rules="[requiredRule, passwordRule]"
                    >
                    </BasePasswordField>
                    <v-btn
                      color="accent"
                      class="px-7"
                      rounded
                      @click="postLogin()"
                      >Login
                    </v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions } from "pinia";
import { useAuthStore } from "../store";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["login"]),
    postLogin() {
      if (this.$refs.form.validate()) {
        var loginData = { email: this.email, password: this.password };
        this.login(loginData)
          .then(() => this.$router.push("/"))
          .catch((err) => console.error(err));
      }
    },
  },
};
</script>

<style scoped>
.all-vh {
  min-height: 100vh;
}
</style>
