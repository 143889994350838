<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>
        Products
      </template>
      <template v-slot:tableActions> <CreateProductButton /> </template>
      <template v-slot:dataTable>
        <ProductsTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import ProductsTable from "../components/ProductsTable.vue";
  import CreateProductButton from "../components/action_buttons/CreateProductButton.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },

          {
            text: "Products",
            disabled: true,
          },
        ],
      };
    },
    components: { ProductsTable,CreateProductButton },
  };
  </script>
  
  <style></style>
  