import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import ProductSubCategoriesService from "../services/ProductSubCategoriesService";
import ProductSubCategoryKeyTransformation from "../models/ProductSubCategoryKeyTransformation";
import PaginatedProductSubCategoryCollection from "../models/PaginatedProductSubCategoryCollection";
import ProductSubCategory from "../models/ProductSubCategory";

export const useProductSubCategoriesStore = defineStore("ProductSubCategoriesStore", {
  state: () => ({
    paginatedProductSubCategory: new PaginatedProductSubCategoryCollection(),
    productSubCategoriesTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: -1,
    },
    searchKey: null,
    productSubCategory: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.productSubCategoriesTableOptions.sortBy.length != 0
          ? {
              sort_by:
                ProductSubCategoryKeyTransformation[
                  this.productSubCategoriesTableOptions.sortBy[0]
                ],
              sort_desc: this.productSubCategoriesTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.productSubCategoriesTableOptions.itemsPerPage != -1
            ? this.productSubCategoriesTableOptions.itemsPerPage
            : null,
        page:
          this.productSubCategoriesTableOptions.itemsPerPage != -1
            ? this.productSubCategoriesTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchProductSubCategories();
    },
    setTableOptionsNFetch(options) {
      this.productSubCategoriesTableOptions = options;
      this.fetchProductSubCategories();
    },
    resetPaginatedCollection() {
      this.paginatedProductSubCategory = new PaginatedProductSubCategoryCollection();
    },
    removeProductSubCategoryLocally(subCategoryId) {
      this.paginatedProductSubCategory.collection.splice(
        this.paginatedProductSubCategory.collection.findIndex(
          (subCategory) => subCategory.id === subCategoryId
        ),
        1
      );
    },
    toggleProductSubCategoryActivationLocally(subCategoryId) {
      this.paginatedProductSubCategory.collection.map((subCategory) => {
        if (subCategory.id == subCategoryId) subCategory.is_active = !subCategory.is_active;
      });
    },
    fetchProductSubCategories() {
      return BaseStore.promiseHandler(
        () =>
          ProductSubCategoriesService.getProductsSubCategories(
            this.getFilterSearchSortPageParameters
          ),
        (data) => {
          this.paginatedProductSubCategory = new PaginatedProductSubCategoryCollection(
            data
          );
        }
      );
    },
    createProductSubCategory(subCategoryData) {
      return BaseStore.promiseHandler(() =>
        ProductSubCategoriesService.addProductSubCategory(subCategoryData)
      );
    },
    editProductSubCategory(subCategoryId, subCategoryData) {
      return BaseStore.promiseHandler(() =>
        ProductSubCategoriesService.updateProductsSubCategory(subCategoryId, subCategoryData)
      );
    },
    fetchProductSubCategory(subCategoryId) {
      return BaseStore.promiseHandler(
        () => ProductSubCategoriesService.getProductsSubCategory(subCategoryId),
        (data) => {
          this.productSubCategory = new ProductSubCategory(data.data);
        }
      );
    },
    deleteProductSubCategory(subCategoryId) {
      return BaseStore.promiseHandler(
        () => ProductSubCategoriesService.deleteProductsSubCategory(subCategoryId),
        () => {
          this.removeProductSubCategoryLocally(subCategoryId);
        }
      );
    },
    toggleProductSubCategoryActivation(subCategoryId, newStatus) {
      return BaseStore.promiseHandler(
        () =>
          ProductSubCategoriesService.updateProductsSubCategoryStatus(
            subCategoryId,
            newStatus
          ),
        () => {
          this.toggleProductSubCategoryActivationLocally(subCategoryId);
        }
      );
    },
  },
});
