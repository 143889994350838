import  Mapper  from "../mapper/Mapper";
import { Notify } from "../notify/Notify";

export default class BaseService {
  static data;


  static checkIfExists(item = null, callbackFn = null) {
    try {
      let checkIfElementExists =
        item !== null && item !== undefined && item !== "";
      if (checkIfElementExists) {
        return callbackFn !== null ? callbackFn(item) : checkIfElementExists;
      }
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static resolveStatusResponse(status, res = {}) {
    try {
      return status < 300 ? Promise.resolve(res) : Promise.reject(res);
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }


  static FormBuilder() {
    try {
      let formData = new FormData();
      Object.keys(this.data).forEach(item => {
        formData.append(item, this.data[item]);
      });
      return formData;
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static objectToFormData(obj, formData = new FormData(), parentKey = '') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const nestedKey = parentKey ? `${parentKey}[${key}]` : key;
        const value = obj[key];
  
        if (value === null || typeof value === 'undefined') {
          continue; // Skip null and undefined values
        }
  
        if (Array.isArray(value)) {
          if (value.length === 0) {
            formData.append(nestedKey + '[]', ''); // Append an empty value to represent an empty array
          } else {
            value.forEach((item, index) => {
              BaseService.objectToFormData(item, formData, `${nestedKey}[${index}]`);
            });
          }
        } else if (typeof value === 'object') {
          BaseService.objectToFormData(value, formData, nestedKey);
        } else {
          formData.append(nestedKey, value);
        }
      }
    }
  
    return formData;
  }
  

  static toString(keys) {
    try {
      keys.forEach(key => {
        if (this.data[key]) {
          this.data[key] = this.data[key].toString();
        }
      });
      return this;
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static setData(data) {
    try {
      this.data = Mapper.camelToUnderscore(data);
      return this;
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static filterForEmpty() {
    try {
      let newData = this.data;
      Object.keys(this.data).forEach(item => {
        this.checkIfEmpty(this.data[item], function(value) {
          delete newData[value];
        });
      });
      this.data = newData;
      return this;
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static checkIfEmpty(item, callback) {
    try {
      if (item === "" || item == null || item == undefined) {
        return callback(item);
      }
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static unsetData(dataToUnset) {
    try {
      let newData = this.data;
      dataToUnset.forEach(item => {
        delete newData[item];
      });
      this.data = newData;
      return this;
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static getWithFormBuilder() {
    try {
      return this.FormBuilder();
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static customizeData(obj) {
    try {
      Object.keys(obj).map(item => (this.data[item] = this.data[obj[item]]));
      return this;
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static get() {
    try {
      return this.data;
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }

  static responseMapperToCamel(res) {
    try {
      if (res.data.response) {
        if (!Array.isArray(res.data.response)) {
          res.data.response = [res.data.response];
        }
        res.data.response.map((item, index) => {
          if (res.data.response[index]) {
            res.data.response[index] = Mapper.underscoreToCamel(item);
          }
        });
      }
      return res;
    } catch (e) {
      Notify.showMessage("error", e);
    }
  }
}
