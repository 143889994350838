import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import VendorCategoriesPage from "../pages/VendorCategoriesPage.vue";
import EditVendorCategoryPage from "../pages/EditVendorCategoryPage.vue";
import VendorCategoryPage from "../pages/VendorCategoryPage.vue";
import CreateVendorCategoryPage from "../pages/CreateVendorCategoryPage.vue";

export default {
  path: "/vendor-categories",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "vendor_categories",
      component: VendorCategoriesPage,
      meta: { title: "Vendor categories" },
    },
    {
      path: "/vendor-categories/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_vendor_category",
          component: EditVendorCategoryPage,
          meta: {
            title: "Edit Vendor category",
          },
        },
      ],
    },
    {
      path: "/vendor-categories/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_vendor_category",
          component: VendorCategoryPage,
          meta: {
            title: "Vendor category",
          },
        },
      ],
    },
    {
      path: "/vendor-categories/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_vendor_category",
          component: CreateVendorCategoryPage,
          meta: {
            title: "Create Vendor category",
          },
        },
      ],
    },
  ],
};
