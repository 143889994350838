import {
  addUserAPI,
  deleteUserAPI,
  getUserAPI,
  getUsersAPI,
  updateUserAPI,
  updateUserStatusAPI,
  getRolesAPI,
} from "./Users.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class UsersService extends BaseService {
  static getUsers(params) {
    return Client.get(getUsersAPI, params).then((res) => Promise.resolve(res));
  }
  static getRoles() {
    return Client.get(getRolesAPI).then((res) => Promise.resolve(res));
  }
  static getUser(userId) {
    return Client.get(getUserAPI(userId)).then((res) => Promise.resolve(res));
  }
  static addUser(userData) {
    return Client.post(addUserAPI, userData).then((res) =>
      Promise.resolve(res)
    );
  }
  static updateUsers(userId, userData) {
    return Client.put(updateUserAPI(userId), userData).then((res) =>
      Promise.resolve(res)
    );
  }
  static updateUsersStatus(userId, newStatus) {
    return Client.post(updateUserStatusAPI(userId), {
      is_active: newStatus,
    }).then((res) => Promise.resolve(res));
  }
  static deleteUsers(userId) {
    return Client.delete(deleteUserAPI(userId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
