<template>
    <BaseVendorCategoryForm
      :vendorCategoryObject="vendorCategory"
      :isEdit="true"
      formActionName="Edit vendor category"
      @vendorCategorySubmitted="
        (categoryData) => sendCategoryData(categoryData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useVendorCategoriesStore } from "../../store/VendorCategoriesStore";
  
  import BaseVendorCategoryForm from "./BaseVendorCategoryForm.vue";
  
  export default {
    props: {
        vendorCategory: Object,
    },
    methods: {
      ...mapActions(useVendorCategoriesStore, ["editVendorCategory"]),
      sendCategoryData(categoryData) {
        this.editVendorCategory(this.vendorCategory.id, categoryData)
        .then(() => {
          this.$router.push({ name: "vendor_categories" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseVendorCategoryForm },
  };
  </script>
  
  <style scoped></style>
  ../../store/VendorCategoriesStore