import {
  addProductSubCategoryAPI,
  deleteProductsSubCategoryAPI,
  getProductSubCategoriesAPI,
  getProductsSubCategoryAPI,
  updateProductsSubCategoryAPI,
  updateProductsSubCategoryStatusAPI,
} from "./ProductSubCategories.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class ProductSubCategoriesService extends BaseService {
  static getProductsSubCategories(params) {
    return Client.get(getProductSubCategoriesAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getProductsSubCategory(subCategoryId) {
    return Client.get(getProductsSubCategoryAPI(subCategoryId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addProductSubCategory(subCategoryData) {
    return Client.post(addProductSubCategoryAPI, subCategoryData).then((res) =>
      Promise.resolve(res)
    );
  }
  static updateProductsSubCategory(subCategoryId, subCategoryData) {
    return Client.put(updateProductsSubCategoryAPI(subCategoryId), subCategoryData).then(
      (res) => Promise.resolve(res)
    );
  }
  static updateProductsSubCategoryStatus(subCategoryId, newStatus) {
    return Client.post(updateProductsSubCategoryStatusAPI(subCategoryId), {
      is_active: newStatus,
    }).then((res) => Promise.resolve(res));
  }
  static deleteProductsSubCategory(subCategoryId) {
    return Client.delete(deleteProductsSubCategoryAPI(subCategoryId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
