<template>
    <BaseAttributeForm
      :attributeObject="attribute"
      :isEdit="true"
      formActionName="Edit attribute"
      @attributeSubmitted="
        (categoryData) => sendData(categoryData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useAttributesStore } from "../../store/AttributesStore";
  
  import BaseAttributeForm from "./BaseAttributeForm.vue";
  
  export default {
    props: {
        attribute: Object,
    },
    methods: {
      ...mapActions(useAttributesStore, ["editAttribute"]),
      sendData(categoryData) {
        this.editAttribute(this.attribute.id, categoryData)
        .then(() => {
          this.$router.push({ name: "attributes" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseAttributeForm },
  };
  </script>
  
  <style scoped></style>
../../store/AttributesStore