<template>
    <BaseVendorForm
      :vendorObject="vendor"
      :isEdit="true"
      formActionName="Edit vendor"
      @vendorSubmitted="
        (categoryData) => sendData(categoryData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useVendorsStore } from "../../store/VendorsStore";
  
  import BaseVendorForm from "./BaseVendorForm.vue";
  
  export default {
    props: {
        vendor: Object,
    },
    methods: {
      ...mapActions(useVendorsStore, ["editVendor"]),
      sendData(categoryData) {
        this.editVendor(this.vendor.id, categoryData)
        .then(() => {
          this.$router.push({ name: "vendors" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseVendorForm },
  };
  </script>
  
  <style scoped></style>
