export const getBannersAPI='/banners';
export function getBannerAPI(bannerId) {
  return "/banners/" + bannerId ;
}
export const addBannerAPI='/banners';
export function updateBannerAPI(bannerId) {
  return "/banners/" + bannerId ;
}
export function updateBannerStatusAPI(bannerId) {
  return "/banners/update-status/" + bannerId ;
}
export function deleteBannerAPI(bannerId) {
  return "/banners/" + bannerId ;
}