<template>
  <div>
    <div class="my-5">
      <span class="primary--text">name :</span>
      {{ vendorCategory.name }}
    </div>
    <div class="my-5">
      <v-checkbox
        readonly
        :input-value="vendorCategory.is_active"
        label="active status"
      ></v-checkbox>
    </div>
    <div class="my-5" v-if="vendorCategory.vendors.length>0">
      <div class="primary--text my-3">Vendors under this categories</div>
      <v-slide-group v-model="current" show-arrows>
        <v-slide-item
          v-for="vendor in vendorCategory.vendors"
          :key="vendor.id"
        >
          <v-card
            :to="{
              name: 'show_vendor',
              params: { id: vendor.id },
            }"
            class="ma-4"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-scale-transition>
                <v-card class="mx-auto" max-width="344">
                  <v-img
                    :src="vendor.image"
                    height="200px"
                  ></v-img>

                  <v-card-subtitle>
                    name: {{ vendor.name }}
                  </v-card-subtitle>
                </v-card>
              </v-scale-transition>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vendorCategory: Object,
  },
};
</script>
