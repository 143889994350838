import { defineStore } from "pinia";

import AuthService from "../services/AuthService";
import BaseStore from "@/core/classes/base/BaseStore";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    isLogoutDialogVisible: false,
    email: localStorage.getItem("email")!="undefined" ?? null,
    username: localStorage.getItem("username") !="undefined"?? null,
    isAuthenticated: localStorage.getItem("token") != null,
    permissions: localStorage.getItem("permissions")!="undefined" ? JSON.parse(localStorage.getItem("permissions")): null,
  }),
  getters: {},
  actions: {
    changeLogoutDialogVisibility() {
      this.isLogoutDialogVisible = !this.isLogoutDialogVisible;
    },
    logoutLocally() {
      localStorage.clear();
      this.isAuthenticated = false;
      this.username=null;
      this.email=null;
      this.permissions=null;
    },
    loginLocally(data) {
      this.setStateData(data);
      this.setLocalStorageData(data);
    },
    setLocalStorageData(data) {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("username", data.data.name);
      localStorage.setItem("email", data.data.email);
      localStorage.setItem(
        "permissions",
        JSON.stringify(data.data.permissions)
      );
    },
    setStateData(data) {
      this.isAuthenticated = true;
      this.email = data.data.email;
      this.username = data.data.name;
      this.permissions = data.data.permissions;
    },
    login(data) {
      return BaseStore.promiseHandler(
        () => AuthService.login(data),
        (data) => {
          this.loginLocally(data);
        }
      );
    },
    logout() {
      return BaseStore.promiseHandler(
        () => AuthService.logout(),
        () => {
          this.logoutLocally();
          this.changeLogoutDialogVisibility();
        }
      );
    },
  },
});
