import UserLayout from "@/core/layouts/UserLayout.vue";
import SpaceReservationsPage from "../pages/SpaceReservationsPage.vue";

export default {
  path: "/space-reservations",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "spaceReservations",
      component: SpaceReservationsPage,
      meta: { title: "SpaceReservation" },
    },
  ],
};
