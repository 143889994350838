import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import AttributesPage from "../pages/AttributesPage.vue";
import EditAttributePage from "../pages/EditAttributePage.vue";
import AttributePage from "../pages/AttributePage.vue";
import CreateAttributePage from "../pages/CreateAttributePage.vue";

export default {
  path: "/attributes",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "attributes",
      component: AttributesPage,
      meta: { title: "Attributes" },
    },
    {
      path: "/attributes/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_attribute",
          component: EditAttributePage,
          meta: {
            title: "Edit Attribute",
          },
        },
      ],
    },
    {
      path: "/attributes/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_attribute",
          component: AttributePage,
          meta: {
            title: "Attribute",
          },
        },
      ],
    },
    {
      path: "/attributes/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_attribute",
          component: CreateAttributePage,
          meta: {
            title: "Create Attribute",
          },
        },
      ],
    },
  ],
};
