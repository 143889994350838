<template>
    <BaseProductSubCategoryForm
      :productSubCategoryObject="productSubCategory"
      :isEdit="true"
      formActionName="Edit product subcategory"
      @productSubCategorySubmitted="
        (subcategoryData) => sendSubCategoryData(subcategoryData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useProductSubCategoriesStore } from "../../store/ProductSubCategoriesStore";
  
  import BaseProductSubCategoryForm from "./BaseProductSubCategoryForm.vue";
  
  export default {
    props: {
        productSubCategory: Object,
    },
    methods: {
      ...mapActions(useProductSubCategoriesStore, ["editProductSubCategory"]),
      sendSubCategoryData(subcategoryData) {
        this.editProductSubCategory(this.productSubCategory.id, subcategoryData)
        .then(() => {
          this.$router.push({ name: "product_subcategories" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseProductSubCategoryForm },
  };
  </script>
  
  <style scoped></style>../../store/ProductSubcategoriesStore