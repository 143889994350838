import { getSpaceReservationsAPI } from "./SpaceReservations.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";

export default class SpaceReservationsService extends BaseService {
  static getSpaceReservations(params) {
    return Client.get(getSpaceReservationsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
}
