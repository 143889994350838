import {
  addBannerAPI,
  deleteBannerAPI,
  getBannerAPI,
  getBannersAPI,
  updateBannerAPI,
  updateBannerStatusAPI,
} from "./Banners.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";

export default class BannerService extends BaseService {
  static getBanners(params) {
    return Client.get(getBannersAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getBanner(bannerId) {
    return Client.get(getBannerAPI(bannerId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addBanner(bannerData) {
    return Client.post(addBannerAPI, bannerData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static updateBanner(bannerId, bannerData) {
    return Client.put(updateBannerAPI(bannerId), bannerData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static updateBannerStatus(bannerId, newStatus) {
    return Client.post(updateBannerStatusAPI(bannerId), {
      is_active: newStatus,
    }).then((res) => Promise.resolve(res));
  }
  static deleteBanner(bannerId) {
    return Client.delete(deleteBannerAPI(bannerId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
