<template>
  <BaseBannerCategoryForm
    :bannerCategoryObject="bannerCategory"
    formActionName="create banner category"
    @bannerCategorySubmitted="
      (categoryData) => sendBannerCategoryData(categoryData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useBannerCategoriesStore } from "../../store/BannerCategoriesStore";

import BannerCategory from "../../models/BannerCategory";
import BaseBannerCategoryForm from "./BaseBannerCategoryForm.vue";

export default {
  data() {
    return {
      bannerCategory: new BannerCategory(),
    };
  },
  methods: {
    ...mapActions(useBannerCategoriesStore, ["createBannerCategory"]),
    sendBannerCategoryData(categoryData) {
      this.createBannerCategory(categoryData)
        .then(() => {
          this.$router.push({ name: "banner_categories" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseBannerCategoryForm },
};
</script>

<style scoped></style>
