import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import UsersPage from "../pages/UsersPage.vue";
import EditUserPage from "../pages/EditUserPage.vue";
import UserPage from "../pages/UserPage.vue";
import CreateUserPage from "../pages/CreateUserPage.vue";

export default {
  path: "/users",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "users",
      component: UsersPage,
      meta: { title: "Users" },
    },
    {
      path: "/users/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_user",
          component: EditUserPage,
          meta: {
            title: "Edit User",
          },
        },
      ],
    },
    {
      path: "/users/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_user",
          component: UserPage,
          meta: {
            title: "User",
          },
        },
      ],
    },
    {
      path: "/users/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_user",
          component: CreateUserPage,
          meta: {
            title: "Create User",
          },
        },
      ],
    },
  ],
};
