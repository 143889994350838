export const getVendorCategoriesAPI='/vendor_categories';
export const getSpacesAPI='/spaces';
export function getVendorsCategoryAPI(categoryId) {
  return "/vendor_categories/" + categoryId ;
}
export const addVendorCategoryAPI='/vendor_categories';
export function updateVendorsCategoryAPI(categoryId) {
  return "/vendor_categories/" + categoryId ;
}
export function updateVendorsCategoryStatusAPI(categoryId) {
  return "/vendor_categories/update-status/" + categoryId ;
}
export function deleteVendorsCategoryAPI(categoryId) {
  return "/vendor_categories/" + categoryId ;
}