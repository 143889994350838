export const getUsersAPI = "/users";
export function getUserAPI(vendorId) {
  return "/users/" + vendorId;
}
export const addUserAPI = "/users";
export function updateUserAPI(vendorId) {
  return "/users/" + vendorId;
}
export function updateUserStatusAPI(vendorId) {
  return "/users/update-status/" + vendorId;
}
export function deleteUserAPI(vendorId) {
  return "/users/" + vendorId;
}
export const getRolesAPI = "/roles";
