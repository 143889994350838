<template>
  <div>
    <v-row>
      <v-col align-self="center" cols="12" md="8">
        <div class="my-5">
          <v-chip
            :to="{
              name: 'show_banner_category',
              params: { id: banner.banner_category_id },
            }"
            dark
            color="accent"
          >
            category : {{ banner.category.type }}</v-chip
          >
        </div>
        <v-carousel height="350px" class="carousel">
          <template v-if="images.length > 0">
            <v-carousel-item
              v-for="(image, i) in images"
              :key="i"
              :src="image"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </template>

          <v-carousel-item v-if="banner.video">
            <video class="media" controls>
              <source :src="banner.video.original_url" />
            </video>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col align-self="center" cols="12" md="4">
        <div class="my-5">
          <span class="primary--text">start date :</span>
          {{ banner.start_date | formatDate }}
        </div>
        <div class="my-5">
          <span class="primary--text">end date :</span>
          {{ banner.end_date | formatDate }}
        </div>
        <div class="my-5">
          <span class="primary--text">vendor :</span>
          <RouterLink
            :to="{
              name: 'show_vendor',
              params: { id: banner.vendor.id },
            }"
          >
            {{ banner.vendor.name }}
          </RouterLink>
        </div>
        <div class="my-5">
          <v-checkbox
            readonly
            :input-value="banner.is_active"
            label="active status"
          ></v-checkbox>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
export default {
  props: {
    banner: Object,
  },
  components: {
    RouterLink,
  },
  computed: {
    images() {
      var images = [];
      images.push(this.banner.image);
      this.banner.images.map((image) => {
        images.push(image.original_url);
      });
      return images;
    },
  },
};
</script>

<style>
.carousel {
  border: 2px solid var(--v-primary-base);
  max-width: 600px;
}
.media {
  width: 100%;
  height: 100%;
}
</style>
