<template>
  <BaseVendorForm
    :vendorObject="vendor"
    formActionName="create vendor"
    @vendorSubmitted="
      (vendorData) => sendVendorData(vendorData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useVendorsStore } from "../../store/VendorsStore";

import Vendor from "../../models/Vendor";
import BaseVendorForm from "./BaseVendorForm.vue";

export default {
  data() {
    return {
      vendor: new Vendor(),
    };
  },
  methods: {
    ...mapActions(useVendorsStore, ["createVendor"]),
    sendVendorData(vendorData) {
      this.createVendor(vendorData)
        .then(() => {
          this.$router.push({ name: "vendors" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseVendorForm },
};
</script>

<style scoped></style>
