export default class VendorCategory {
  id;
  name;
  is_active;
  created_at;
  vendors = [];
  space_id;
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.is_active = data?.is_active;
    this.space_id = data?.space_id;
    this.created_at = data?.created_at;
    this.vendors = data?.vendors;
  }
}
