import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import BannerCategoriesService from "../services/BannerCategoriesService";
import BannerCategoryKeyTransformation from "../models/BannerCategoryKeyTransformation";
import PaginatedBannerCategoryCollection from "../models/PaginatedBannerCategoryCollection";
import BannerCategory from "../models/BannerCategory";

export const useBannerCategoriesStore = defineStore("BannerCategoriesStore", {
  state: () => ({
    paginatedBannerCategory: new PaginatedBannerCategoryCollection(),
    bannerCategoriesTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: -1,
    },
    searchKey: null,
    is_active_filter:null,
    bannerCategory: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.bannerCategoriesTableOptions.sortBy.length != 0
          ? {
              sort_by:
                BannerCategoryKeyTransformation[
                  this.bannerCategoriesTableOptions.sortBy[0]
                ],
              sort_desc: this.bannerCategoriesTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        is_active:this.is_active_filter,
        items_per_page:
          this.bannerCategoriesTableOptions.itemsPerPage != -1
            ? this.bannerCategoriesTableOptions.itemsPerPage
            : null,
        page:
          this.bannerCategoriesTableOptions.itemsPerPage != -1
            ? this.bannerCategoriesTableOptions.page
            : null,

        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchBannerCategories();
    },
    setTableOptionsNFetch(options) {
      this.bannerCategoriesTableOptions = options;
      this.fetchBannerCategories();
    },
    resetPaginatedCollection() {
      this.paginatedBannerCategory = new PaginatedBannerCategoryCollection();
    },
    removeBannerCategoryLocally(categoryId) {
      this.paginatedBannerCategory.collection.splice(
        this.paginatedBannerCategory.collection.findIndex(
          (category) => category.id === categoryId
        ),
        1
      );
    },
    toggleBannerCategoryActivationLocally(categoryId) {
      this.paginatedBannerCategory.collection.map((category) => {
        if (category.id == categoryId) category.is_active = !category.is_active;
      });
    },
    fetchBannerCategories(isPaginated = true) {
      var fetchParams = isPaginated
        ? this.getFilterSearchSortPageParameters
        : {};
      return BaseStore.promiseHandler(
        () => BannerCategoriesService.getBannersCategories(fetchParams),
        (data) => {
          this.paginatedBannerCategory = new PaginatedBannerCategoryCollection(
            data
          );
        }
      );
    },
    createBannerCategory(categoryData) {
      return BaseStore.promiseHandler(() =>
        BannerCategoriesService.addBannerCategory(categoryData)
      );
    },
    editBannerCategory(categoryId, categoryData) {
      return BaseStore.promiseHandler(() =>
        BannerCategoriesService.updateBannersCategory(categoryId, categoryData)
      );
    },
    fetchBannerCategory(categoryId) {
      return BaseStore.promiseHandler(
        () => BannerCategoriesService.getBannersCategory(categoryId),
        (data) => {
          this.bannerCategory = new BannerCategory(data.data);
        }
      );
    },
    deleteBannerCategory(categoryId) {
      return BaseStore.promiseHandler(
        () => BannerCategoriesService.deleteBannersCategory(categoryId),
        () => {
          this.removeBannerCategoryLocally(categoryId);
        }
      );
    },
    toggleBannerCategoryActivation(categoryId, newStatus) {
      return BaseStore.promiseHandler(
        () =>
          BannerCategoriesService.updateBannersCategoryStatus(
            categoryId,
            newStatus
          ),
        () => {
          this.toggleBannerCategoryActivationLocally(categoryId);
        }
      );
    },
  },
});
