import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import ProductsService from "../services/ProductsService";
import MediaService from "../../media/services/MediaService.api";
import ProductKeyTransformation from "../models/ProductKeyTransformation";
import PaginatedProductCollection from "../models/PaginatedProductCollection";
import Product from "../models/Product";

export const useProductsStore = defineStore("ProductsStore", {
  state: () => ({
    paginatedProducts: new PaginatedProductCollection(),
    productsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    product: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.productsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                ProductKeyTransformation[this.productsTableOptions.sortBy[0]],
              sort_desc: this.productsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.productsTableOptions.itemsPerPage != -1
            ? this.productsTableOptions.itemsPerPage
            : null,
        page:
          this.productsTableOptions.itemsPerPage != -1
            ? this.productsTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchProducts();
    },
    setTableOptionsNFetch(options) {
      this.productsTableOptions = options;
      this.fetchProducts();
    },
    resetPaginatedCollection() {
      this.paginatedProducts = new PaginatedProductCollection();
    },
    removeProductLocally(productId) {
      this.paginatedProducts.collection.splice(
        this.paginatedProducts.collection.findIndex(
          (product) => product.id === productId
        ),
        1
      );
    },
    toggleProductActivationLocally(productId) {
      this.paginatedProducts.collection.map((category) => {
        if (category.id == productId) category.is_active = !category.is_active;
      });
    },
    addMediaToProductLocally(data) {
      this.product.images.push(data);
    },
    deleteMediaFromProductLocally(mediaId) {
      this.product.images.splice(
        this.product.images.findIndex((image) => image.uuid == mediaId),
        1
      );
    },
    fetchProducts(isPaginated = true) {
      var fetchParams = isPaginated
        ? this.getFilterSearchSortPageParameters
        : {};
      return BaseStore.promiseHandler(
        () => ProductsService.getProducts(fetchParams),
        (data) => {
          this.paginatedProducts = new PaginatedProductCollection(data);
        }
      );
    },
    createProduct(productData) {
      return BaseStore.promiseHandler(() =>
        ProductsService.addProduct(productData)
      );
    },
    editProduct(productId, productData) {
      return BaseStore.promiseHandler(() =>
        ProductsService.updateProducts(productId, productData)
      );
    },
    fetchProduct(productId) {
      return BaseStore.promiseHandler(
        () => ProductsService.getProduct(productId),
        (data) => {
          this.product = new Product(data.data);
        }
      );
    },
    deleteProduct(productId) {
      return BaseStore.promiseHandler(
        () => ProductsService.deleteProducts(productId),
        () => {
          this.removeProductLocally(productId);
        }
      );
    },
    toggleProductActivation(productId, newStatus) {
      return BaseStore.promiseHandler(
        () => ProductsService.updateProductStatus(productId, newStatus),
        () => {
          this.toggleProductActivationLocally(productId);
        }
      );
    },
    addMediaToProduct(modelId, file) {
      return BaseStore.promiseHandler(
        () =>
          MediaService.addNewMedia({
            model_id: modelId,
            model_class: "Product",
            image: file,
            collection_name: "products-images",
          }),
        (data) => {
          this.addMediaToProductLocally(data);
        }
      );
    },
    deleteMediaFromProduct(mediaId) {
      return BaseStore.promiseHandler(
        () => MediaService.deleteMedia(mediaId),
        () => {
          this.deleteMediaFromProductLocally(mediaId);
        }
      );
    },
  },
});
