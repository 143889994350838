<template>
  <BaseProductSubCategoryForm
    :productSubCategoryObject="productSubCategory"
    formActionName="create product subcategory"
    @productSubCategorySubmitted="
      (subcategoryData) => sendProductSubCategoryData(subcategoryData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useProductSubCategoriesStore } from "../../store/ProductSubCategoriesStore";

import ProductSubCategory from "../../models/ProductSubCategory";
import BaseProductSubCategoryForm from "./BaseProductSubCategoryForm.vue";

export default {
  data() {
    return {
      productSubCategory: new ProductSubCategory(),
    };
  },
  methods: {
    ...mapActions(useProductSubCategoriesStore, ["createProductSubCategory"]),
    sendProductSubCategoryData(subcategoryData) {
      this.createProductSubCategory(subcategoryData)
        .then(() => {
          this.$router.push({ name: "product_subcategories" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseProductSubCategoryForm },
};
</script>

<style scoped></style>../../store/ProductSubcategoriesStore