export const getProductsAPI='/products';
export function getProductAPI(productId) {
  return "/products/" + productId ;
}
export const addProductAPI='/products';
export function updateProductAPI(productId) {
  return "/products/" + productId ;
}
export function updateProductStatusAPI(bannerId) {
  return "/products/update-status/" + bannerId ;
}
export function deleteProductAPI(productId) {
  return "/products/" + productId ;
}