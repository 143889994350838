<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <WalletDetails :wallet="wallet"
        /></v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useWalletsStore } from "../store/WalletsStore";
import WalletDetails from "../components/WalletDetails.vue";

export default {
  mounted() {
    var walletId = this.$route.params.id;
    this.fetchWallet(walletId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useWalletsStore, ["wallet"]),
  },
  methods: {
    ...mapActions(useWalletsStore, ["fetchWallet"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },
        {
          text: "Wallets",
          href: "/wallets",
          disabled: false,
        },
        {
          text: `Wallet #${this.$route.params.id}`,
          disabled: true,
        },
      ],
    };
  },
  components: {
    WalletDetails,
  },
};
</script>../store/WalletsStore