import { EventBus } from "../../../main";

export class Notify {
  static getMessage(code) {
    let codeAvailable = {
      400: "ُAn Error occurred.",
      401: "Your session has expired, please log in again.",
      403: "Your request can't be completed due to lack of authorization.",
      301: "Please try again.",
      422: "Input validation error.",
      500: "Server Error.",
    };
    return codeAvailable[code] != null || undefined
      ? codeAvailable[code]
      : code;
  }

  static showMessage(type, message) {
    EventBus.$notify({
      group: "public",
      type: type,
      title: message,
      duration: 5000,
    });
  }
}
