import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import WalletsService from "../services/WalletsService";
import WalletKeyTransformation from "../models/WalletKeyTransformation";
import PaginatedWalletCollection from "../models/PaginatedWalletCollection";
import Wallet from "../models/Wallet";

export const useWalletsStore = defineStore("WalletsStore", {
  state: () => ({
    paginatedWallets: new PaginatedWalletCollection(),
    walletsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    wallet: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.walletsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                WalletKeyTransformation[this.walletsTableOptions.sortBy[0]],
              sort_desc: this.walletsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.walletsTableOptions.itemsPerPage != -1
            ? this.walletsTableOptions.itemsPerPage
            : null,
        page:
          this.walletsTableOptions.itemsPerPage != -1
            ? this.walletsTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchWallets();
    },
    setTableOptionsNFetch(options) {
      this.walletsTableOptions = options;
      this.fetchWallets();
    },
    resetPaginatedCollection() {
      this.paginatedWallets = new PaginatedWalletCollection();
    },
    updateWalletAmountLocally(walletId,amount){
      this.paginatedWallets.collection.map((wallet)=>{
        if( wallet.id==walletId){
          wallet.amount=parseFloat(wallet.amount)+parseFloat(amount);
        }
      })
    },
    fetchWallets(isPaginated = true) {
      var fetchParams = isPaginated
        ? this.getFilterSearchSortPageParameters
        : {};
      return BaseStore.promiseHandler(
        () => WalletsService.getWallets(fetchParams),
        (data) => {
          this.paginatedWallets = new PaginatedWalletCollection(data);
        }
      );
    },
    fetchWallet(walletId) {
      return BaseStore.promiseHandler(
        () => WalletsService.getWallet(walletId),
        (data) => {
          this.wallet = new Wallet(data.data);
        }
      );
    },
    addAmountToWallet(walletId,amount){
      return BaseStore.promiseHandler(
        () => WalletsService.addAmountToWallet(walletId,amount),
        () => {
          this.updateWalletAmountLocally(walletId,amount)
        }
      );
    }
  },
});
