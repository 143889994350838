import UserLayout from "@/core/layouts/UserLayout.vue";
import HomePage from "../pages/HomePage.vue";

export default {
  path: "/",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "home",
      component: HomePage,
      meta: {
        title: "Home",
      },
    },
  ],
};
