<template>
  <BaseCRUDPageLayout :links="links">
    <GreetingCard />
  </BaseCRUDPageLayout>
</template>

<script>
import GreetingCard from "../components/GreetingCard.vue";

export default {
  components: {
    GreetingCard,
  },
  data() {
    return {
      links: [
        {
          text: "Home",
          disabled: true,
        },
      ],
    };
  },
};
</script>
