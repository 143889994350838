<template>
    <BaseProductForm
      :productObject="product"
      :isEdit="true"
      formActionName="Edit product"
      @productSubmitted="
        (categoryData) => sendData(categoryData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useProductsStore } from "../../store/ProductsStore";
  
  import BaseProductForm from "./BaseProductForm.vue";
  
  export default {
    props: {
        product: Object,
    },
    methods: {
      ...mapActions(useProductsStore, ["editProduct"]),
      sendData(categoryData) {
        this.editProduct(this.product.id, categoryData)
        .then(() => {
          this.$router.push({ name: "products" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseProductForm },
  };
  </script>
  
  <style scoped></style>
../../store/ProductsStore