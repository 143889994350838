<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <ProductCategoryDetails :productCategory="productCategory"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditProductCategoryButton
            :item="productCategory"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteProductCategoryButton
            :item="productCategory"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useProductCategoriesStore } from "../store/ProductCategoriesStore";
import ProductCategoryDetails from "../components/ProductCategoryDetails.vue";
import EditProductCategoryButton from "../components/action_buttons/EditProductCategoryButton.vue";
import DeleteProductCategoryButton from "../components/action_buttons/DeleteProductCategoryButton.vue";

export default {
  mounted() {
    var productId = this.$route.params.id;
    this.fetchProductCategory(productId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useProductCategoriesStore, ["productCategory"]),
  },
  methods: {
    ...mapActions(useProductCategoriesStore, ["fetchProductCategory"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },
        {
          text: "Product categories",
          href: "/product-categories",
          disabled: false,
        },
        {
          text: `Product category #${this.$route.params.id}`,
          disabled: true,
        },
      ],
    };
  },
  components: {
    ProductCategoryDetails,
    EditProductCategoryButton,
    DeleteProductCategoryButton,
  },
};
</script>
