<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> Edit attribute </template>
      <v-container>
        <v-row>
          <v-col v-if="!fetchingData" cols="12">
            <EditAttributeForm :attribute="attribute" />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState, mapActions } from "pinia";
  import { useAttributesStore } from "../store/AttributesStore";
  import EditAttributeForm from "../components/forms/EditAttributeForm.vue";
  
  export default {
    mounted() {
      var attributeId = this.$route.params.id;
      this.fetchAttribute(attributeId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useAttributesStore, ["attribute"]),
    },
    methods: {
      ...mapActions(useAttributesStore, ["fetchAttribute"]),
    },
    data() {
      return {
        fetchingData:true,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },
          {
            text: "Attributes",
            href: "/attributes",
            disabled: false,
          },
          {
            text: `Edit attribute #${this.$route.params.id}`,
            disabled: true,
          },
        ],
      };
    },
    components: { EditAttributeForm },
  };
  </script>
../store/AttributesStore