<template>
  <BaseBannerForm
    :bannerObject="banner"
    formActionName="create banner"
    @bannerSubmitted="
      (bannerData) => sendBannerData(bannerData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useBannersStore } from "../../store/BannersStore";

import Banner from "../../models/Banner";
import BaseBannerForm from "./BaseBannerForm.vue";

export default {
  data() {
    return {
      banner: new Banner(),
    };
  },
  methods: {
    ...mapActions(useBannersStore, ["createBanner"]),
    sendBannerData(bannerData) {
      this.createBanner(bannerData)
        .then(() => {
          this.$router.push({ name: "banners" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseBannerForm },
};
</script>

<style scoped></style>
../../store/BannersStore