import Vue from "vue";
import App from "./App.vue";
import { PiniaVuePlugin } from "pinia";
import VueCompositionAPI from "@vue/composition-api";
import pinia from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import Client from "./core/classes/client/Client";
import Notifications from "vue-notification";
import "@/core/base/GlobalBaseComponentsRegistration";
import GlobalDirectives from "@/core/directives";
import GlobalFilters from "@/core/filters";

//initializing http client with interceptors
window.axios = Client.initHttp(axios);

//registering dependencies
Vue.use(PiniaVuePlugin);
Vue.use(VueCompositionAPI);
Vue.use(Notifications);


Vue.config.productionTip = false;

//registering global directives
Vue.use(GlobalDirectives);
//registering global filters
Vue.use(GlobalFilters);

//creating event bus
export const EventBus = new Vue();

import router from "./router";

//mounting vue instance
new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
