<template>
  <v-form ref="amountForm">
    <v-text-field
      v-model="amount"
      class="mt-3"
      dense
      type="number"
      :rules="[requiredRule, numberRule]"
      placeholder="add amount"
      outlined
    >
      <template v-slot:append>
        <div class="mt-0 mr-neg">
          <v-btn color="primary" height="40">
            <v-icon large @click="sendAddAmountToWallet()">mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions } from "pinia";
import { useWalletsStore } from "./../../store/WalletsStore";
export default {
  props: {
    item: Object,
  },
  mixins: [FormValidationRulesMixin],

  data() {
    return {
      amount: null,
    };
  },
  methods: {
    ...mapActions(useWalletsStore, ["addAmountToWallet"]),
    sendAddAmountToWallet() {
      if (this.$refs.amountForm.validate()) {
        this.addAmountToWallet(this.item.id, this.amount)
          .then(() => {
            this.$refs.amountForm.reset();
          })
          .catch((err) => console.error(err));
      }
    },
  },
};
</script>
<style scoped>
.mr-neg {
  margin-right: -12px;
}
.v-text-field {
  max-width: 200px;
}
</style>
