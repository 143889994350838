<template>
    <div>
      <v-btn :to="{name:'create_banner_category'}" color="accent"
        ><v-icon>mdi-plus</v-icon> create new banner category
      </v-btn>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
  };
  </script>
  