<template>
  <v-expansion-panel class="transparent">
    <v-expansion-panel-header class="white--text">
      <v-icon class="mx-4"> {{ icon }} </v-icon> {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ml-0">
      <v-tabs center-active background-color="transparent" vertical optional>
        <v-tab
          class="f-s-md justify-start white--text text-caption"
          v-for="tab in tabs"
          :key="tab.title"
          :to="tab.link"
        >
          {{ tab.title }}
        </v-tab>
        <v-tabs-slider color="white"></v-tabs-slider>
      </v-tabs>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    permissionList: Array,
    title: String,
    icon: String,
    tabs: Array,
  },
};
</script>

<style scoped>
.f-s-md {
  font-size: medium;
}
.justify-start {
  justify-content: start;
}
</style>
