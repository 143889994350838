var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedProducts,"show-expand":true},on:{"debouncedSearch":function (searchKey) { return _vm.setSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ShowProductButton',{attrs:{"item":item}}),_c('EditProductButton',{attrs:{"item":item}}),_c('DeleteProductButton',{attrs:{"Button":"","item":item}})]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('ProductStatusToggler',{attrs:{"item":item}})]}},{key:"expansion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-5"},[_c('span',{staticClass:"primary--text"},[_vm._v("description : ")]),_vm._v(_vm._s(item.description)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }