var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedSpaceReservation},on:{"debouncedSearch":function (searchKey) { return _vm.setSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"item.vendor_category_id",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{
        name: 'show_vendor_category',
        params: { id: item.vendor_category_id },
      }}},[_vm._v(" "+_vm._s(item.vendor_category_id)+" ")])]}},{key:"item.end_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.end_date))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }