import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import BannerCategoriesPage from "../pages/BannerCategoriesPage.vue";
import EditBannerCategoryPage from "../pages/EditBannerCategoryPage.vue";
import BannerCategoryPage from "../pages/BannerCategoryPage.vue";
import CreateBannerCategoryPage from "../pages/CreateBannerCategoryPage.vue";

export default {
  path: "/banner-categories",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "banner_categories",
      component: BannerCategoriesPage,
      meta: { title: "Banner categories" },
    },
    {
      path: "/banner-categories/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_banner_category",
          component: EditBannerCategoryPage,
          meta: {
            title: "Edit Banner category",
          },
        },
      ],
    },
    {
      path: "/banner-categories/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_banner_category",
          component: BannerCategoryPage,
          meta: {
            title: "Banner category",
          },
        },
      ],
    },
    {
      path: "/banner-categories/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_banner_category",
          component: CreateBannerCategoryPage,
          meta: {
            title: "Create Banner category",
          },
        },
      ],
    },
  ],
};
