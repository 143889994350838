export const getProductCategoriesAPI='/product_categories';
export function getProductsCategoryAPI(categoryId) {
  return "/product_categories/" + categoryId ;
}
export const addProductCategoryAPI='/product_categories';
export function updateProductsCategoryAPI(categoryId) {
  return "/product_categories/" + categoryId ;
}
export function updateProductsCategoryStatusAPI(categoryId) {
  return "/product_categories/update-status/" + categoryId ;
}
export function deleteProductsCategoryAPI(categoryId) {
  return "/product_categories/" + categoryId ;
}