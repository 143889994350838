import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import VendorsPage from "../pages/VendorsPage.vue";
import EditVendorPage from "../pages/EditVendorPage.vue";
import VendorPage from "../pages/VendorPage.vue";
import CreateVendorPage from "../pages/CreateVendorPage.vue";

export default {
  path: "/vendors",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "vendors",
      component: VendorsPage,
      meta: { title: "Vendors" },
    },
    {
      path: "/vendors/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_vendor",
          component: EditVendorPage,
          meta: {
            title: "Edit Vendor",
          },
        },
      ],
    },
    {
      path: "/vendors/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_vendor",
          component: VendorPage,
          meta: {
            title: "Vendor",
          },
        },
      ],
    },
    {
      path: "/vendors/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_vendor",
          component: CreateVendorPage,
          meta: {
            title: "Create Vendor",
          },
        },
      ],
    },
  ],
};
