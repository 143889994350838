<template>
    <v-switch
      :input-value="item.is_active"
      inset
      readonly
      @click="toggleActivation()"
    ></v-switch>
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useBannersStore } from "../../store/BannersStore";
  
  export default {
    props: {
      item: Object,
    },
    data() {
      return {
        banner: this.item,
      };
    },
    methods: {
      ...mapActions(useBannersStore, ["toggleBannerActivation"]),
      toggleActivation() {
        // toggle object enable value
        this.banner.is_active = this.banner.is_active == 0 ? 1 : 0;
        // send editing request with new enable status
        
        this.toggleBannerActivation(this.item.id, this.banner.is_active)
          .then()
          .catch((err) => console.error(err));
      },
    },
  };
  </script>
  ../../store/BannersStore