export default class SpaceReservation {
  id;
  full_name;
  phone;
  vendor_name;
  notes;
  vendor_category_id;
  created_at;
  constructor(data) {
    this.id = data?.id;
    this.full_name = data?.full_name;
    this.phone = data?.phone;
    this.vendor_name = data?.vendor_name;
    this.notes = data?.notes;
    this.vendor_category_id = data?.vendor_category_id;
    this.created_at = data?.created_at;
  }
}
