<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedVendorCategory"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowVendorCategoryButton :item="item" />
      <EditVendorCategoryButton :item="item" />
      <DeleteVendorCategoryButton :item="item" />
    </template>
    <template v-slot:[`item.is_active`]="{ item }">
      <VendorCategoryStatusToggler :item="item" />
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ item.created_at | formatDate }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useVendorCategoriesStore } from "../store/VendorCategoriesStore";
import ShowVendorCategoryButton from "./action_buttons/ShowVendorCategoryButton.vue";
import EditVendorCategoryButton from "./action_buttons/EditVendorCategoryButton.vue";
import DeleteVendorCategoryButton from "./action_buttons/DeleteVendorCategoryButton.vue";
import VendorCategoryStatusToggler from "./action_buttons/VendorCategoryStatusToggler.vue";

export default {
  components: {
    ShowVendorCategoryButton,
    EditVendorCategoryButton,
    DeleteVendorCategoryButton,
    VendorCategoryStatusToggler,
  },
  computed: {
    ...mapState(useVendorCategoriesStore, ["paginatedVendorCategory"]),
    ...mapWritableState(useVendorCategoriesStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "name", value: "name" },
        { text: "status", value: "is_active" },
        { text: "created at", value: "created_at" },
        { text: "actions", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useVendorCategoriesStore, [
      "fetchVendorCategories",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  mounted() {
    this.fetchVendorCategories()
      .then()
      .catch((err) => console.error(err));
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>
../store/VendorCategoriesStore
