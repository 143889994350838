<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>
        Commissions
      </template>
      <template v-slot:tableActions> <CreateCommissionButton /> </template>
      <template v-slot:dataTable>
        <CommissionsTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import CommissionsTable from "../components/CommissionsTable.vue";
  import CreateCommissionButton from "../components/action_buttons/CreateCommissionButton.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },

          {
            text: "Commission",
            disabled: true,
          },
        ],
      };
    },
    components: { CommissionsTable,CreateCommissionButton },
  };
  </script>
  
  <style></style>
  