<template>
  <BaseProductForm
    :productObject="product"
    formActionName="create product"
    @productSubmitted="
      (productData) => sendProductData(productData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useProductsStore } from "../../store/ProductsStore";

import Product from "../../models/Product";
import BaseProductForm from "./BaseProductForm.vue";

export default {
  data() {
    return {
      product: new Product(),
    };
  },
  methods: {
    ...mapActions(useProductsStore, ["createProduct"]),
    sendProductData(productData) {
      this.createProduct(productData)
        .then(() => {
          this.$router.push({ name: "products" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseProductForm },
};
</script>

<style scoped></style>
../../store/ProductsStore