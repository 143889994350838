import {
  addProductAPI,
  deleteProductAPI,
  getProductsAPI,
  getProductAPI,
  updateProductAPI,
  updateProductStatusAPI,
} from "./Products.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class ProductsService extends BaseService {
  static getProducts(params) {
    return Client.get(getProductsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getProduct(productId) {
    return Client.get(getProductAPI(productId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addProduct(productData) {
    return Client.post(addProductAPI, productData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static updateProducts(productId, productData) {
    return Client.put(
      updateProductAPI(productId),
      BaseService.objectToFormData(productData),
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    ).then((res) => Promise.resolve(res));
  }
  static updateProductStatus(productId, newStatus) {
    return Client.post(updateProductStatusAPI(productId), {
      is_active: newStatus,
    }).then((res) => Promise.resolve(res));
  }
  static deleteProducts(productId) {
    return Client.delete(deleteProductAPI(productId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
