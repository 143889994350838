export default {
  id: "id",
  name: "name",
  description: "description",
  quantity: "quantity",
  price: "price",
  attributes: "attributes",
  features: "features",
  created_at: "created_at",
  vendor: "vendor",
  vendor_id: "vendor_id",
  commission: "commission",
  commission_id: "commission_id",
  commission_value: "commission_value",
  category: "category",
  categorizable_id: "categorizable_id",
  categorizable_type: "categorizable_type",
  is_active: "is_active",
  is_cancelable: "is_cancelable",
  is_replaceable: "is_replaceable",
  is_returnable: "is_returnable",
};
