<template>
    <BaseUserForm
      :userObject="user"
      :isEdit="true"
      formActionName="Edit user"
      @userSubmitted="
        (categoryData) => sendData(categoryData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useUsersStore } from "../../store/UsersStore";
  
  import BaseUserForm from "./BaseUserForm.vue";
  
  export default {
    props: {
        user: Object,
    },
    methods: {
      ...mapActions(useUsersStore, ["editUser"]),
      sendData(categoryData) {
        this.editUser(this.user.id, categoryData)
        .then(() => {
          this.$router.push({ name: "users" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseUserForm },
  };
  </script>
  
  <style scoped></style>