import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import BannersService from "../services/BannerService";
import MediaService from "../../media/services/MediaService.api";
import BannerKeyTransformation from "../models/BannerKeyTransformation";
import PaginatedBannerCollection from "../models/PaginatedBannerCollection";
import Banner from "../models/Banner";

export const useBannersStore = defineStore("BannersStore", {
  state: () => ({
    paginatedBanner: new PaginatedBannerCollection(),
    bannersTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    banner: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.bannersTableOptions.sortBy.length != 0
          ? {
              sort_by:
                BannerKeyTransformation[this.bannersTableOptions.sortBy[0]],
              sort_desc: this.bannersTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.bannersTableOptions.itemsPerPage != -1
            ? this.bannersTableOptions.itemsPerPage
            : null,
        page:
          this.bannersTableOptions.itemsPerPage != -1
            ? this.bannersTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchBanners();
    },
    setTableOptionsNFetch(options) {
      this.bannersTableOptions = options;
      this.fetchBanners();
    },
    resetPaginatedCollection() {
      this.paginatedBanner = new PaginatedBannerCollection();
    },
    removeBannerLocally(bannerId) {
      this.paginatedBanner.collection.splice(
        this.paginatedBanner.collection.findIndex(
          (category) => category.id === bannerId
        ),
        1
      );
    },
    toggleBannerActivationLocally(bannerId) {
      this.paginatedBanner.collection.map((category) => {
        if (category.id == bannerId) category.is_active = !category.is_active;
      });
    },
    addMediaToBannerLocally(data) {
      this.banner.images.push(data);
    },
    deleteMediaFromBannerLocally(mediaId) {
      this.banner.images.splice(
        this.banner.images.findIndex((image) => image.uuid == mediaId),
        1
      );
    },
    fetchBanners() {
      return BaseStore.promiseHandler(
        () => BannersService.getBanners(this.getFilterSearchSortPageParameters),
        (data) => {
          this.paginatedBanner = new PaginatedBannerCollection(data);
        }
      );
    },
    createBanner(bannerData) {
      return BaseStore.promiseHandler(() =>
        BannersService.addBanner(bannerData)
      );
    },
    editBanner(bannerId, bannerData) {
      return BaseStore.promiseHandler(() =>
        BannersService.updateBanner(bannerId, bannerData)
      );
    },
    fetchBanner(bannerId) {
      return BaseStore.promiseHandler(
        () => BannersService.getBanner(bannerId),
        (data) => {
          this.banner = new Banner(data.data);
        }
      );
    },
    deleteBanner(bannerId) {
      return BaseStore.promiseHandler(
        () => BannersService.deleteBanner(bannerId),
        () => {
          this.removeBannerLocally(bannerId);
        }
      );
    },
    toggleBannerActivation(bannerId, newStatus) {
      return BaseStore.promiseHandler(
        () => BannersService.updateBannerStatus(bannerId, newStatus),
        () => {
          this.toggleBannerActivationLocally(bannerId);
        }
      );
    },
    addMediaToBanner(modelId, file,isImage=true) {
      return BaseStore.promiseHandler(
        () =>
          MediaService.addNewMedia({
            model_id: modelId,
            model_class: "Banner",
            image:isImage? file:null,
            video:isImage? null:file,
            collection_name: isImage?"banners-images":"banners-videos",
          }),
        (data) => {
          this.addMediaToBannerLocally(data);
        }
      );
    },
    deleteMediaFromBanner(mediaId) {
      return BaseStore.promiseHandler(
        () => MediaService.deleteMedia(mediaId),
        () => {
          this.deleteMediaFromBannerLocally(mediaId);
        }
      );
    },
  },
});
