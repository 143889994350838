<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <CommissionDetails :commission="commission"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditCommissionButton
            :item="commission"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteCommissionButton
            :item="commission"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useCommissionsStore } from "../store/CommissionsStore";
import CommissionDetails from "../components/CommissionDetails.vue";
import EditCommissionButton from "../components/action_buttons/EditCommissionButton.vue";
import DeleteCommissionButton from "../components/action_buttons/DeleteCommissionButton.vue";

export default {
  mounted() {
    var commissionId = this.$route.params.id;
    this.fetchCommission(commissionId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useCommissionsStore, ["commission"]),
  },
  methods: {
    ...mapActions(useCommissionsStore, ["fetchCommission"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },
        {
          text: "Commission",
          href: "/commissions",
          disabled: false,
        },
        {
          text: `Commission #${this.$route.params.id}`,
          disabled: true,
        },
      ],
    };
  },
  components: {
    CommissionDetails,
    EditCommissionButton,
    DeleteCommissionButton,
  },
};
</script>