<template>
  <v-textarea
    v-on="$listeners"
    v-bind="$attrs"
    outlined
  ></v-textarea>
</template>

<script>
export default {
  name: "BaseTextArea",
};
</script>

<style scoped>
.v-textarea {
  max-width: 450px;
}
</style>