<template>
    <BaseBannerForm
      :bannerObject="banner"
      :isEdit="true"
      formActionName="Edit banner "
      @bannerSubmitted="
        (bannerData) => sendBannerData(bannerData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useBannersStore } from "../../store/BannersStore";
  
  import BaseBannerForm from "./BaseBannerForm.vue";
  
  export default {
    props: {
        banner: Object,
    },
    methods: {
      ...mapActions(useBannersStore, ["editBanner"]),
      sendBannerData(bannerData) {
        this.editBanner(this.banner.id, bannerData)
        .then(() => {
          this.$router.push({ name: "banners" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseBannerForm },
  };
  </script>
  
  <style scoped></style>