<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>
        Product Categories
      </template>
      <template v-slot:tableActions> <CreateProductCategoryButton /> </template>
      <template v-slot:dataTable>
        <ProductCategoriesTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import ProductCategoriesTable from "../components/ProductCategoriesTable.vue";
  import CreateProductCategoryButton from "../components/action_buttons/CreateProductCategoryButton.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },

          {
            text: "Product categories",
            disabled: true,
          },
        ],
      };
    },
    components: { ProductCategoriesTable,CreateProductCategoryButton },
  };
  </script>
  
  <style></style>
  