import { BaseError } from "../base/BaseError";
import { Notify } from "../notify/Notify";

export class RequestError extends BaseError {
  message ="Bad request, please try again!";
  
  dispatchError() {
    Notify.showMessage("error", this.message);
  }
}
