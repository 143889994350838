import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import Banner from "./Banner";

export default class PaginatedBannerCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    if (paginationResponse?.data?.data) {
      paginationResponse?.data?.data?.map((banner) => {
        this.collection.push(new Banner(banner));
      });
    } else {
      paginationResponse?.data?.map((banner) => {
        this.collection.push(new Banner(banner));
      });
    }
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse?.data?.data?.map((banner) => {
      this.collection.push(new Banner(banner));
    });
  }
}
