<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>
        Vendors
      </template>
      <template v-slot:tableActions> <CreateVendorButton /> </template>
      <template v-slot:dataTable>
        <VendorsTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import VendorsTable from "../components/VendorsTable.vue";
  import CreateVendorButton from "../components/action_buttons/CreateVendorButton.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },

          {
            text: "Vendors",
            disabled: true,
          },
        ],
      };
    },
    components: { VendorsTable,CreateVendorButton },
  };
  </script>
  
  <style></style>
  