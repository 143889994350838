<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <AttributeDetails :attribute="attribute"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditAttributeButton
            :item="attribute"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteAttributeButton
            :item="attribute"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useAttributesStore } from "../store/AttributesStore";
import AttributeDetails from "../components/AttributeDetails.vue";
import EditAttributeButton from "../components/action_buttons/EditAttributeButton.vue";
import DeleteAttributeButton from "../components/action_buttons/DeleteAttributeButton.vue";

export default {
  mounted() {
    var attributeId = this.$route.params.id;
    this.fetchAttribute(attributeId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useAttributesStore, ["attribute"]),
  },
  methods: {
    ...mapActions(useAttributesStore, ["fetchAttribute"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },
        {
          text: "Attributes",
          href: "/attributes",
          disabled: false,
        },
        {
          text: `Attribute #${this.$route.params.id}`,
          disabled: true,
        },
      ],
    };
  },
  components: {
    AttributeDetails,
    EditAttributeButton,
    DeleteAttributeButton,
  },
};
</script>../store/AttributesStore