export default class BannerCategory {
    id;
    type;
    is_active;
    created_at;
    banners=[];
    constructor(data) {
      this.id = data?.id;
      this.type = data?.type;
      this.is_active = data?.is_active;
      this.created_at = data?.created_at;
      this.banners=data?.banners;
    }
  }
  