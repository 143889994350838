export const getAttributesAPI='/attributes';
export function getAttributeAPI(attributeId) {
  return "/attributes/" + attributeId ;
}
export const addAttributeAPI='/attributes';
export function updateAttributeAPI(attributeId) {
  return "/attributes/" + attributeId ;
}
export function deleteAttributeAPI(attributeId) {
  return "/attributes/" + attributeId ;
}