<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-chip
          :to="
            product.category.type == 'ProductCategory'
              ? {
                  name: 'show_product_category',
                  params: { id: product.category.id },
                }
              : {
                  name: 'show_product_subcategory',
                  params: { id: product.category.id },
                }
          "
          dark
          color="accent"
          class="mb-5"
          >category : {{ product.category.name }}</v-chip
        >
        <v-carousel height="350px" class="carousel">
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
            :src="image"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <div class="my-5">
          <span class="primary--text">name :</span>
          {{ product.name }}
        </div>
        <div class="my-5">
          <span class="primary--text">description :</span>
          {{ product.description }}
        </div>
        <div class="my-5">
          <span class="primary--text">price :</span>
          {{ product.price }}
        </div>
        <div class="my-5">
          <span class="primary--text">quantity :</span>
          {{ product.quantity }}
        </div>
        <div class="my-5">
          <span class="primary--text">created at :</span>
          {{ product.created_at | formatDate }}
        </div>
        <div class="my-5" v-if="product.attributes.length > 0">
          <span class="primary--text">product attributes :</span>
          <div v-for="(attribute, index) in product.attributes" :key="index">
            <v-row>
              <v-col cols="12">
                {{ index + 1 }}) {{ attribute.name }} : value:
                {{ attribute.selected_value }}, quantity:
                {{ attribute.quantity }}
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="my-5" v-if="product.features.length > 0">
          <span class="primary--text">product features :</span>
          <div v-for="(feature, index) in product.features" :key="index">
            <v-row>
              <v-col cols="12">
                {{ index + 1 }}) {{ feature.title }}: {{ feature.content }}
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="my-5">
          <span class="primary--text">vendor :</span>
          <RouterLink
            :to="{
              name: 'show_vendor',
              params: { id: product.vendor.id },
            }"
          >
            {{ product.vendor.name }}
          </RouterLink>
        </div>
        <div class="my-5">
          <span class="primary--text">commission : </span>
          {{ product.commission.name }} (
          {{ product.commission.commission_value }} )
          <div></div>
        </div>
        <div class="my-5">
          <v-checkbox
            readonly
            :input-value="product.is_active"
            label="active status"
          ></v-checkbox>
        </div>
        <div class="my-5">
          <v-checkbox
            readonly
            :input-value="product.is_returnable"
            label="returnability status"
          ></v-checkbox>
        </div>
        <div class="my-5">
          <v-checkbox
            readonly
            :input-value="product.is_cancelable"
            label="cancelability status"
          ></v-checkbox>
        </div>
        <div class="my-5">
          <v-checkbox
            readonly
            :input-value="product.is_replaceable"
            label="replicability status"
          ></v-checkbox>
        </div>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import { RouterLink } from "vue-router";

export default {
  props: {
    product: Object,
  },
  components: {
    RouterLink,
  },
  computed: {
    images() {
      var images = [];
      images.push(this.product.image);
      this.product.images.map((image)=>{
        images.push(image.original_url)
      })
      return images;
    },
  },
};
</script>
