<template>
  <div>
    <v-checkbox
      :false-value="null"
      :true-value="1"
      v-model="is_active_filter"
      label="active items"
    ></v-checkbox>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { useBannerCategoriesStore } from "../store/BannerCategoriesStore";

export default {
  data() {
    return {
      is_active: this.is_active_filter,
    };
  },
  computed: {
    ...mapWritableState(useBannerCategoriesStore, ["is_active_filter"]),
  },
  methods: {
    ...mapActions(useBannerCategoriesStore, ["fetchBannerCategories"]),
  },
  watch: {
    is_active_filter() {
      this.fetchBannerCategories();
    },
  },
};
</script>
