<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="start">
        <v-col cols="12" md="6">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="vendor.name"
            label="Name"
            placeholder="vendor name"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextField
            :rules="[requiredRule]"
            v-model="vendor.address"
            label="Address"
            placeholder="vendor address"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextField
            :rules="[requiredRule, numberRule, phoneNumberRule]"
            v-model="vendor.phone"
            label="Phone"
            placeholder="vendor phone"
            type="number"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextField
            :rules="[requiredRule, emailRule]"
            v-model="vendor.email"
            label="email"
            placeholder="vendor email"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextArea
            :rules="[requiredRule]"
            v-model="vendor.description"
            label="Description"
            placeholder="vendor description"
            persistent-placeholder
          ></BaseTextArea>
        </v-col>
        <v-col cols="12" md="6">
          <BaseSelect
            :rules="[requiredRule]"
            :items="paginatedVendorCategory.collection"
            v-model="vendor.vendor_category_id"
            item-value="id"
            chips
            clearable
            label="category"
            placeholder="choose vendor category"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.name }}</v-chip>
            </template>
          </BaseSelect>
          <!--images-->
          <template v-if="!isEdit">
            <!-- main image -->
            <v-file-input
              :rules="[requiredRule]"
              v-model="mainImage"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Main image"
              placeholder="input the main image of the vendor"
              persistent-placeholder
              outlined
            >
              <template v-slot:selection>
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeMainImage()"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img class="ma-2 rounded image" :src="mainImageUrl"></v-img>
                </div>
              </template>
            </v-file-input>

            <!-- other images -->
            <v-file-input
              v-model="images"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Other images"
              placeholder="input any other images of the vendor"
              persistent-placeholder
              outlined
              multiple
            >
              <template v-slot:selection="data">
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeImage(data.index)"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src="imageUrls[data.index]"
                  ></v-img>
                </div>
              </template>
            </v-file-input>
          </template>
          <template v-else>
            <!-- main image -->
            <v-file-input
              :rules="[requiredRule]"
              v-model="mainImage"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Main image"
              placeholder="input the main image of the vendor"
              persistent-placeholder
              outlined
            >
              <template v-slot:selection>
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeMainImage()"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src="
                      typeof mainImage == 'string' ? mainImage : mainImageUrl
                    "
                  ></v-img>
                </div>
              </template>
            </v-file-input>

            <v-row align="center">
              <v-col cols="6">
                <h2 class="secondary--text my-3">uploaded images</h2>
              </v-col>
              <v-col cols="6">
                <v-btn dark color="primary" @click="toggleAddNewImage">
                  <v-icon class="mr-2">mdi-plus</v-icon> upload new image
                </v-btn></v-col
              >
            </v-row>
            <v-form v-if="showAddNewImage" ref="newImageForm">
              <v-row>
                <v-col align-self="center" cols="12" md="8" class="mr-0 pr-0">
                  <v-file-input
                    :rules="[requiredRule]"
                    v-model="newImage"
                    accept="image/*"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
                    label="New image"
                    placeholder="upload a new image of the vendor"
                    persistent-placeholder
                    outlined
                  >
                    <template v-slot:selection>
                      <div style="position: relative">
                        <v-btn
                          @click.stop="removeNewImage()"
                          fab
                          x-small
                          class="delete-icon"
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                        <v-img
                          class="ma-2 rounded image"
                          :src="newImageUrl"
                        ></v-img>
                      </div>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" md="4" class="mr-0 pr-0">
                  <v-btn dark color="primary" @click="validateThenAddNewImage">
                    add
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-row>
              <v-col cols="4" v-for="image in vendor.images" :key="image.uuid">
                <div style="position: relative">
                  <v-btn
                    @click.stop="deletePreviousImage(image.uuid)"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src="image.original_url"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <v-row align="center" justify="start">
        <v-btn color="accent" medium @click="emitSubmission()">
          {{ formActionName }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions, mapState } from "pinia";
import { useVendorsStore } from "../../store/VendorsStore";
import { useVendorCategoriesStore } from "../../../vendors_categories/store/VendorCategoriesStore";
import { EventBus } from "@/main";

export default {
  props: {
    vendorObject: Object,
    formActionName: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vendor: this.vendorObject,
      mainImage: null,
      newImage: null,
      showAddNewImage: null,
      images: [],
    };
  },
  watch: {
    vendorObject: {
      immediate: true,
      handler() {
        this.vendor = this.vendorObject;
      },
    },
    vendor: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  mounted() {
    this.fetchVendorCategories(false)
      .then(() => {
        if (this.isEdit) this.mainImage = this.vendor.image;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    imageUrls() {
      var urls = [];
      this.images.map((image) => urls.push(URL.createObjectURL(image)));
      return urls;
    },
    mainImageUrl() {
      return this.mainImage ? URL.createObjectURL(this.mainImage) : "";
    },
    newImageUrl() {
      return this.newImage ? URL.createObjectURL(this.newImage) : "";
    },
    ...mapState(useVendorCategoriesStore, ["paginatedVendorCategory"]),
  },
  methods: {
    removeMainImage() {
      this.mainImage = null;
    },
    removeNewImage() {
      this.newImage = null;
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    toggleAddNewImage() {
      this.showAddNewImage = !this.showAddNewImage;
    },
    ...mapActions(useVendorCategoriesStore, ["fetchVendorCategories"]),
    ...mapActions(useVendorsStore, [
      "addMediaToVendor",
      "deleteMediaFromVendor",
    ]),
    validateThenAddNewImage() {
      if (this.$refs.newImageForm.validate()) {
        this.addMediaToVendor(this.vendor.id, this.newImage)
          .then(() => {
            this.$refs.newImageForm.reset();
          })
          .catch((err) => console.error(err));
      }
    },
    deletePreviousImage(imageId) {
      this.deleteMediaFromVendor(imageId)
        .then()
        .catch((err) => console.error(err));
    },
    emitSubmission() {
      if (this.$refs.form.validate()) {
       var emittedVendor = {
          ...this.vendor,
          is_active: this.vendor.is_active ? 1 : 0,
          image: this.mainImage,
          images:this.isEdit?this.vendor.images: this.images,
        };
        if( typeof emittedVendor.image  == 'string'){
          emittedVendor.image=null;
        }
        if(this.isEdit){
          emittedVendor.images=null;
        }
        EventBus.$emit("formSubmitted");
        this.$emit("vendorSubmitted", emittedVendor);
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
.image {
  height: auto;
  max-width: 150px;
  border: 2px solid var(--v-primary-base);
}
.delete-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}
</style>
