import VendorCategory from "@/modules/vendors_categories/models/VendorCategory";

export default class Vendor {
  id;
  name;
  description;
  address;
  phone;
  email;
  is_active;
  created_at;
  category = new VendorCategory();
  vendor_category_name;
  vendor_category_id;
  image;
  images = [];
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.description = data?.description;
    this.address = data?.address;
    this.phone = data?.phone;
    this.email = data?.email;
    this.is_active = data?.is_active;
    this.image = data?.image;
    if (data?.images) {
      for (const [key, image] of Object.entries(data?.images)) {
        key;
        this.images.push(image);
      }
    }
    this.category = new VendorCategory(data?.category);
    this.vendor_category_name = data?.vendor_category_name;
    this.vendor_category_id = data?.vendor_category_id;
    this.created_at = data?.created_at;
  }
}
