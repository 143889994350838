<template>
  <div>
    <router-view />
    <LoadingProgress></LoadingProgress>
    <NotificationAlert></NotificationAlert>
  </div>
</template>
<script>
import LoadingProgress from "../components/loader/LoadingProgress";
import NotificationAlert from "../components/notifications/NotificationAlert";

export default {
  components: {
    NotificationAlert,
    LoadingProgress,
  },
};
</script>


