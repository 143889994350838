<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <BannerCategoryDetails :bannerCategory="bannerCategory"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditBannerCategoryButton
            :item="bannerCategory"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteBannerCategoryButton
            :item="bannerCategory"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useBannerCategoriesStore } from "../store/BannerCategoriesStore";
import BannerCategoryDetails from "../components/BannerCategoryDetails.vue";
import EditBannerCategoryButton from "../components/action_buttons/EditBannerCategoryButton.vue";
import DeleteBannerCategoryButton from "../components/action_buttons/DeleteBannerCategoryButton.vue";

export default {
  mounted() {
    var bannerId = this.$route.params.id;
    this.fetchBannerCategory(bannerId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useBannerCategoriesStore, ["bannerCategory"]),
  },
  methods: {
    ...mapActions(useBannerCategoriesStore, ["fetchBannerCategory"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },
        {
          text: "Banner categories",
          href: "/banner-categories",
          disabled: false,
        },
        {
          text: `Banner category #${this.$route.params.id}`,
          disabled: true,
        },
      ],
    };
  },
  components: {
    BannerCategoryDetails,
    EditBannerCategoryButton,
    DeleteBannerCategoryButton,
  },
};
</script>
