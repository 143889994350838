import {
  addBannerCategoryAPI,
  deleteBannersCategoryAPI,
  getBannersCategoriesAPI,
  getBannersCategoryAPI,
  updateBannersCategoryAPI,
  updateBannersCategoryStatusAPI,
} from "./BannerCategories.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class BannerCategoriesService extends BaseService {
  static getBannersCategories(params) {
    return Client.get(getBannersCategoriesAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getBannersCategory(categoryId) {
    return Client.get(getBannersCategoryAPI(categoryId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addBannerCategory(categoryData) {
    return Client.post(addBannerCategoryAPI, categoryData).then((res) =>
      Promise.resolve(res)
    );
  }
  static updateBannersCategory(categoryId, categoryData) {
    return Client.put(updateBannersCategoryAPI(categoryId), categoryData).then(
      (res) => Promise.resolve(res)
    );
  }
  static updateBannersCategoryStatus(categoryId, newStatus) {
    return Client.post(updateBannersCategoryStatusAPI(categoryId), {
      is_active: newStatus,
    }).then((res) => Promise.resolve(res));
  }
  static deleteBannersCategory(categoryId) {
    return Client.delete(deleteBannersCategoryAPI(categoryId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
