<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> Wallets </template>
    <template v-slot:dataTable>
      <WalletsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import WalletsTable from "../components/WalletsTable.vue";

export default {
  data() {
    return {
      id: this.$route.params.id,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },

        {
          text: "Wallets",
          disabled: true,
        },
      ],
    };
  },
  components: { WalletsTable },
};
</script>

<style></style>
