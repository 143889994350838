<template>
  <v-app>
    <v-main class="light">
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>
<style>
* {
  font-family: "Noto Sans";
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #f0ebe6;
}
::-webkit-scrollbar-thumb {
  background-color: #decebe;
}
</style>
