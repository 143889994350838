import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import BannersPage from "../pages/BannersPage.vue";
import EditBannerPage from "../pages/EditBannerPage.vue";
import BannerPage from "../pages/BannerPage.vue";
import CreateBannerPage from "../pages/CreateBannerPage.vue";

export default {
  path: "/banners",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "banners",
      component: BannersPage,
      meta: { title: "Banner" },
    },
    {
      path: "/banners/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_banner",
          component: EditBannerPage,
          meta: {
            title: "Edit Banner",
          },
        },
      ],
    },
    {
      path: "/banners/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_banner",
          component: BannerPage,
          meta: {
            title: "Banner",
          },
        },
      ],
    },
    {
      path: "/banners/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_banner",
          component: CreateBannerPage,
          meta: {
            title: "Create Banner",
          },
        },
      ],
    },
  ],
};
