import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import ProductCategoriesService from "../services/ProductCategoriesService";
import ProductCategoryKeyTransformation from "../models/ProductCategoryKeyTransformation";
import PaginatedProductCategoryCollection from "../models/PaginatedProductCategoryCollection";
import ProductCategory from "../models/ProductCategory";

export const useProductCategoriesStore = defineStore("ProductCategoriesStore", {
  state: () => ({
    paginatedProductCategory: new PaginatedProductCategoryCollection(),
    productCategoriesTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: -1,
    },
    searchKey: null,
    productCategory: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.productCategoriesTableOptions.sortBy.length != 0
          ? {
              sort_by:
                ProductCategoryKeyTransformation[
                  this.productCategoriesTableOptions.sortBy[0]
                ],
              sort_desc: this.productCategoriesTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.productCategoriesTableOptions.itemsPerPage != -1
            ? this.productCategoriesTableOptions.itemsPerPage
            : null,
        page:
          this.productCategoriesTableOptions.itemsPerPage != -1
            ? this.productCategoriesTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchProductCategories();
    },
    setTableOptionsNFetch(options) {
      this.productCategoriesTableOptions = options;
      this.fetchProductCategories();
    },
    resetPaginatedCollection() {
      this.paginatedProductCategory = new PaginatedProductCategoryCollection();
    },
    removeProductCategoryLocally(categoryId) {
      this.paginatedProductCategory.collection.splice(
        this.paginatedProductCategory.collection.findIndex(
          (category) => category.id === categoryId
        ),
        1
      );
    },
    toggleProductCategoryActivationLocally(categoryId) {
      this.paginatedProductCategory.collection.map((category) => {
        if (category.id == categoryId) category.is_active = !category.is_active;
      });
    },
    fetchProductCategories() {
      return BaseStore.promiseHandler(
        () =>
          ProductCategoriesService.getProductsCategories(
            this.getFilterSearchSortPageParameters
          ),
        (data) => {
          this.paginatedProductCategory = new PaginatedProductCategoryCollection(
            data
          );
        }
      );
    },
    createProductCategory(categoryData) {
      return BaseStore.promiseHandler(() =>
        ProductCategoriesService.addProductCategory(categoryData)
      );
    },
    editProductCategory(categoryId, categoryData) {
      return BaseStore.promiseHandler(() =>
        ProductCategoriesService.updateProductsCategory(categoryId, categoryData)
      );
    },
    fetchProductCategory(categoryId) {
      return BaseStore.promiseHandler(
        () => ProductCategoriesService.getProductsCategory(categoryId),
        (data) => {
          this.productCategory = new ProductCategory(data.data);
        }
      );
    },
    deleteProductCategory(categoryId) {
      return BaseStore.promiseHandler(
        () => ProductCategoriesService.deleteProductsCategory(categoryId),
        () => {
          this.removeProductCategoryLocally(categoryId);
        }
      );
    },
    toggleProductCategoryActivation(categoryId, newStatus) {
      return BaseStore.promiseHandler(
        () =>
          ProductCategoriesService.updateProductsCategoryStatus(
            categoryId,
            newStatus
          ),
        () => {
          this.toggleProductCategoryActivationLocally(categoryId);
        }
      );
    },
  },
});
