<template>
  <BaseVendorCategoryForm
    :vendorCategoryObject="vendorCategory"
    formActionName="create vendor category"
    @vendorCategorySubmitted="
      (categoryData) => sendVendorCategoryData(categoryData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useVendorCategoriesStore } from "../../store/VendorCategoriesStore";

import VendorCategory from "../../models/VendorCategory";
import BaseVendorCategoryForm from "./BaseVendorCategoryForm.vue";

export default {
  data() {
    return {
      vendorCategory: new VendorCategory(),
    };
  },
  methods: {
    ...mapActions(useVendorCategoriesStore, ["createVendorCategory"]),
    sendVendorCategoryData(categoryData) {
      this.createVendorCategory(categoryData)
        .then(() => {
          this.$router.push({ name: "vendor_categories" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseVendorCategoryForm },
};
</script>

<style scoped></style>
../../store/VendorCategoriesStore../../models/VendorCategory