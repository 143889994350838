<template>
  <v-menu
    ref="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <BaseTextField
        hide-details="auto"
        ref="dateField"
        persistent-placeholder
        outlined
        prepend-inner-icon="mdi-calendar"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="{ ...$listeners, ...on }"
        class="my-5"
      ></BaseTextField>
    </template>
    <v-date-picker v-bind="$attrs" v-on="$listeners" no-title></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "BaseDateField",
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
</style>