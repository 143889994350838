<template>
  <div>
    <div class="my-5">
      <span class="primary--text">name :</span>
      {{ user.first_name }} {{ user.last_name }}
      <!--change display of role to role name-->
      <v-chip
        dark
        color="accent"
        class="mx-3"
      >
        role : {{ user.role_id }}</v-chip
      >
    </div>
    <div class="my-5">
      <span class="primary--text">phone :</span>
      {{ user.phone }}
    </div>
    <div class="my-5">
      <span class="primary--text">email :</span>
      {{ user.email }}
    </div>
    <div class="my-5">
      <span class="primary--text">address :</span>
      {{ user.address }}
    </div>
    <div class="my-5">
      <span class="primary--text">gender :</span>
      {{ user.gender }} 
      <v-icon v-if="user.gender == 'male'">mdi-gender-male</v-icon>
      <v-icon v-else>mdi-gender-female</v-icon>
    </div>
    <div class="my-5">
      <span class="primary--text">last login at :</span>
      {{ user.last_login_at | formatDate }}
    </div>
    <div class="my-5">
      <span class="primary--text">created at :</span>
      {{ user.created_at | formatDate }}
    </div>
    <div class="my-5">
      <v-checkbox
        readonly
        :input-value="user.status"
        label="active status"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      current: null,
    };
  },
};
</script>
