export default {
  id: "id",
  first_name: "first_name",
  last_name: "last_name",
  address: "address",
  phone: "phone",
  email: "email",
  last_login_at: "last_login_at",
  gender: "gender",
  created_at: "created_at",
  status: "status",
  role_id: "role_id",
  role: "role",
};
