<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>
        Product Subcategories
      </template>
      <template v-slot:tableActions> <CreateProductSubCategoryButton /> </template>
      <template v-slot:dataTable>
        <ProductSubCategoriesTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import ProductSubCategoriesTable from "../components/ProductSubCategoriesTable.vue";
  import CreateProductSubCategoryButton from "../components/action_buttons/CreateProductSubCategoryButton.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },

          {
            text: "Product subcategories",
            disabled: true,
          },
        ],
      };
    },
    components: { ProductSubCategoriesTable,CreateProductSubCategoryButton },
  };
  </script>
  
  <style></style>
  