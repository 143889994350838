<template>
  <v-form ref="form">
    <v-container class="mt-5">
      <v-row justify="start">
        <v-col cols="12" md="6" class="py-0">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="product.name"
            label="Name"
            placeholder="product name"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextField
            :rules="[requiredRule, numberRule]"
            v-model="product.quantity"
            label="Quantity"
            placeholder="product quantity"
            type="number"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextField
            :rules="[requiredRule, numberRule]"
            v-model="product.price"
            label="Price"
            placeholder="product price"
            type="number"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextArea
            :rules="[requiredRule]"
            v-model="product.description"
            label="Description"
            placeholder="product description"
            persistent-placeholder
          ></BaseTextArea>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <BaseSelect
            :rules="[requiredRule]"
            :items="paginatedVendors.collection"
            v-model="product.vendor_id"
            item-value="id"
            chips
            clearable
            label="Vendor"
            placeholder="choose product vendor"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.name }}</v-chip>
            </template>
          </BaseSelect>
          <BaseSelect
            :items="paginatedCommission.collection"
            v-model="product.commission_id"
            item-value="id"
            chips
            clearable
            label="Commission"
            placeholder="add product commission"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.name }}</v-chip>
            </template>
          </BaseSelect>
          <BaseTextField
            v-if="product.commission_id"
            :rules="[requiredRule, numberRule]"
            type="number"
            v-model="product.commission_value"
            label="Commission value"
            placeholder="commission value"
            persistent-placeholder
          ></BaseTextField>
          <v-switch
            v-model="product.is_returnable"
            inset
            label="returnable status"
          ></v-switch>
          <v-switch
            v-model="product.is_cancelable"
            inset
            label="cancelable status"
          ></v-switch>
          <v-switch
            v-model="product.is_replaceable"
            inset
            label="replaceable status"
          ></v-switch>
        </v-col>
      </v-row>
      <!--images-->
      <template v-if="!isEdit">
        <v-row justify="start">
          <v-col cols="12" md="6" class="py-0">
            <!-- main image -->
            <v-file-input
              :rules="[requiredRule]"
              v-model="mainImage"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Main image"
              placeholder="input the main image of the product"
              persistent-placeholder
              outlined
            >
              <template v-slot:selection>
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeMainImage()"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img class="ma-2 rounded image" :src="mainImageUrl"></v-img>
                </div>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <!-- other images -->
            <v-file-input
              v-model="images"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Other images"
              placeholder="input any other images of the product"
              persistent-placeholder
              outlined
              multiple
            >
              <template v-slot:selection="data">
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeImage(data.index)"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src="imageUrls[data.index]"
                  ></v-img>
                </div>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row justify="start">
          <v-col cols="12" md="6" class="py-0">
            <!-- main image -->
            <v-file-input
              :rules="[requiredRule]"
              v-model="mainImage"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Main image"
              placeholder="input the main image of the product"
              persistent-placeholder
              outlined
            >
              <template v-slot:selection>
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeMainImage()"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src="
                      typeof mainImage == 'string' ? mainImage : mainImageUrl
                    "
                  ></v-img>
                </div>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-row align="center">
              <v-col cols="6">
                <h2 class="secondary--text my-3">uploaded images</h2>
              </v-col>
              <v-col cols="6">
                <v-btn dark color="primary" @click="toggleAddNewImage">
                  <v-icon class="mr-2">mdi-plus</v-icon> upload new image
                </v-btn></v-col
              >
            </v-row>
            <v-form v-if="showAddNewImage" ref="newImageForm">
              <v-row>
                <v-col align-self="center" cols="12" md="8" class="mr-0 pr-0">
                  <v-file-input
                    :rules="[requiredRule]"
                    v-model="newImage"
                    accept="image/*"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
                    label="New image"
                    placeholder="upload a new image of the product"
                    persistent-placeholder
                    outlined
                  >
                    <template v-slot:selection>
                      <div style="position: relative">
                        <v-btn
                          @click.stop="removeNewImage()"
                          fab
                          x-small
                          class="delete-icon"
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                        <v-img
                          class="ma-2 rounded image"
                          :src="newImageUrl"
                        ></v-img>
                      </div>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" md="4" class="mr-0 pr-0">
                  <v-btn dark color="primary" @click="validateThenAddNewImage">
                    add
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-row class="mb-10">
              <v-col cols="4" v-for="image in product.images" :key="image.uuid">
                <div style="position: relative">
                  <v-btn
                    @click.stop="deletePreviousImage(image.uuid)"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src="image.original_url"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <v-row justify="start">
        <v-col cols="12" md="6" class="py-0">
          <BaseSelect
            :rules="[requiredRule]"
            :items="paginatedProductCategory.collection"
            v-model="mainCategoryId"
            item-value="id"
            chips
            clearable
            label="Main category"
            placeholder="choose the product main category"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.name }}</v-chip>
            </template>
          </BaseSelect>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <BaseSelect
            v-if="mainCategoryId"
            :items="subcategoriesOfMainCategory(mainCategoryId)"
            v-model="subcategoryId"
            item-value="id"
            chips
            clearable
            label="Subcategory"
            placeholder="choose the product subcategory"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.name }}</v-chip>
            </template>
          </BaseSelect>
        </v-col>
      </v-row>
      <v-row align="center" justify="start">
        <v-col cols="12" class="py-0">
          <v-row align="center">
            <v-col cols="6">
              <h2 class="secondary--text my-3">product attributes</h2>
            </v-col>
            <v-col cols="6">
              <v-btn dark color="primary" @click="toggleAttributeForm">
                <v-icon class="mr-2">mdi-plus</v-icon> add new attribute
              </v-btn></v-col
            >
          </v-row>
          <v-form v-if="showAttributeForm" ref="attributesForm">
            <v-row align="center">
              <v-col cols="12" md="3" class="mr-0 pr-0">
                <BaseSelect
                  :rules="[requiredRule]"
                  :items="paginatedAttributes.collection"
                  v-model="attribute.id"
                  item-value="id"
                  chips
                  clearable
                  label="Attribute name"
                  placeholder="add an attribute to the product"
                >
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip> {{ item.name }}</v-chip>
                  </template>
                </BaseSelect>
              </v-col>
              <v-col cols="12" md="3" class="mr-0 pr-0">
                <BaseSelect
                  :rules="[requiredRule]"
                  :items="valuesOfAttribute(attribute.id)"
                  v-model="attribute.selected_value"
                  item-value="value"
                  chips
                  clearable
                  label="Attribute value"
                  placeholder="choose a value for the product's attribute"
                >
                  <template v-slot:item="{ item }">
                    {{ item.value }}
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip> {{ item.value }}</v-chip>
                  </template>
                </BaseSelect>
              </v-col>
              <v-col cols="12" md="3" class="mr-0 pr-0">
                <BaseTextField
                  :rules="[requiredRule, numberRule]"
                  v-model="attribute.quantity"
                  type="number"
                  height="68"
                  label="quantity"
                  placeholder="attribute quantity"
                  persistent-placeholder
                ></BaseTextField>
              </v-col>
              <v-col cols="12" md="3" class="mr-0 pr-0">
                <v-btn dark color="primary" @click="validateThenAddAttribute">
                  add
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <div
            v-for="(attribute, index) in product.attributes"
            :key="attribute.attribute_id"
          >
            <v-card class="my-3 pa-5 elevation-10" max-width="300">
              <v-row>
                <v-col cols="10">
                  {{ index + 1 }}) {{ getAttributeName(attribute.id) }} : value:
                  {{ attribute.selected_value }}, quantity:
                  {{ attribute.quantity }}
                </v-col>
                <v-col cols="2">
                  <v-btn icon @click="deleteAttribute(index)"
                    ><v-icon color="error">mdi-delete</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="start">
        <v-col cols="12" class="py-0">
          <v-row align="center">
            <v-col cols="6">
              <h2 class="secondary--text my-3">product features</h2>
            </v-col>
            <v-col cols="6">
              <v-btn dark color="primary" @click="toggleFeatureForm">
                <v-icon class="mr-2">mdi-plus</v-icon> add new feature
              </v-btn></v-col
            >
          </v-row>
          <v-form v-if="showFeatureForm" ref="featuresForm">
            <v-row align="center">
              <v-col cols="12" md="4" class="mr-0 pr-0">
                <BaseTextField
                  :rules="[requiredRule]"
                  v-model="feature.title"
                  height="68"
                  label="title"
                  placeholder="feature title"
                  persistent-placeholder
                ></BaseTextField>
              </v-col>
              <v-col cols="12" md="4" class="mr-0 pr-0">
                <BaseTextField
                  :rules="[requiredRule]"
                  v-model="feature.content"
                  height="68"
                  label="content"
                  placeholder="feature content"
                  persistent-placeholder
                ></BaseTextField>
              </v-col>
              <v-col cols="12" md="4" class="mr-0 pr-0">
                <v-btn dark color="primary" @click="validateThenAddFeature">
                  add
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <div v-for="(feature, index) in product.features" :key="index">
            <v-card class="my-3 pa-5 elevation-10" max-width="300">
              <v-row>
                <v-col cols="10">
                  {{ index + 1 }}) {{ feature.title }}: {{ feature.content }}
                </v-col>
                <v-col cols="2">
                  <v-btn icon @click="deleteFeature(index)"
                    ><v-icon color="error">mdi-delete</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="start">
        <v-btn class="my-5" color="accent" medium @click="emitSubmission()">
          {{ formActionName }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions, mapState } from "pinia";
import { useProductCategoriesStore } from "../../../products_categories/store/ProductCategoriesStore";
import { useProductSubCategoriesStore } from "../../../products_subcategories/store/ProductSubCategoriesStore";
import { useVendorsStore } from "../../../vendors/store/VendorsStore";
import { useCommissionsStore } from "../../../commissions/store/CommissionsStore";
import { useProductsStore } from "../../store/ProductsStore";
import { useAttributesStore } from "../../../attributes/store/AttributesStore";
import { EventBus } from "@/main";
import { Notify } from "@/core/classes/notify/Notify";

export default {
  props: {
    productObject: Object,
    formActionName: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      product: this.productObject,
      attribute: {},
      feature: {},
      mainCategoryId: null,
      subcategoryId: null,
      showAttributeForm: false,
      showFeatureForm: false,
      mainImage: null,
      newImage: null,
      showAddNewImage: null,
      images: [],
    };
  },
  watch: {
    productObject: {
      immediate: true,
      handler() {
        this.product = this.productObject;
        if (this.productObject?.category?.type == "ProductCategory") {
          this.mainCategoryId = this.productObject.category.id;
          this.product.categorizable_type = "Category";
        }
        if (this.productObject?.category?.type == "ProductSubCategory") {
          this.fetchProductSubCategories().then(() => {
            this.mainCategoryId = this.getMainCategoryFromSubcategory(
              this.productObject.category.id
            );
            this.subcategoryId = this.productObject.category.id;
            this.product.categorizable_type = "Subcategory";
          });
        }
      },
    },
    product: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
    mainCategoryId() {
      this.product.categorizable_id = this.mainCategoryId;
      this.product.categorizable_type = "Category";
    },
    subcategoryId() {
      if (this.subcategoryId) {
        //case of changing the value of a subcategory
        this.product.categorizable_id = this.subcategoryId;
        this.product.categorizable_type = "Subcategory";
      } //case of clearing a subcategory
      else {
        this.product.categorizable_id = this.mainCategoryId;
        this.product.categorizable_type = "Category";
      }
    },
  },
  mixins: [FormValidationRulesMixin],
  mounted() {
    Promise.all([
      this.fetchProductCategories(false),
      this.fetchProductSubCategories(false),
      this.fetchVendors(false),
      this.fetchCommissions(false),
      this.fetchAttributes(false),
    ])
      .then(() => {
        if (this.isEdit) this.mainImage = this.product.image;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    imageUrls() {
      var urls = [];
      this.images.map((image) => urls.push(URL.createObjectURL(image)));
      return urls;
    },
    mainImageUrl() {
      return this.mainImage ? URL.createObjectURL(this.mainImage) : "";
    },
    newImageUrl() {
      return this.newImage ? URL.createObjectURL(this.newImage) : "";
    },
    ...mapState(useProductCategoriesStore, ["paginatedProductCategory"]),
    ...mapState(useProductSubCategoriesStore, ["paginatedProductSubCategory"]),
    ...mapState(useVendorsStore, ["paginatedVendors"]),
    ...mapState(useCommissionsStore, ["paginatedCommission"]),
    ...mapState(useAttributesStore, ["paginatedAttributes"]),
  },
  methods: {
    removeMainImage() {
      this.mainImage = null;
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    removeNewImage() {
      this.newImage = null;
    },
    toggleAddNewImage() {
      this.showAddNewImage = !this.showAddNewImage;
    },
    ...mapActions(useProductCategoriesStore, ["fetchProductCategories"]),
    ...mapActions(useProductSubCategoriesStore, ["fetchProductSubCategories"]),
    ...mapActions(useVendorsStore, ["fetchVendors"]),
    ...mapActions(useCommissionsStore, ["fetchCommissions"]),
    ...mapActions(useAttributesStore, ["fetchAttributes"]),
    ...mapActions(useProductsStore, [
      "addMediaToProduct",
      "deleteMediaFromProduct",
    ]),
    validateThenAddNewImage() {
      if (this.$refs.newImageForm.validate()) {
        this.addMediaToProduct(this.product.id, this.newImage)
          .then(() => {
            this.$refs.newImageForm.reset();
          })
          .catch((err) => console.error(err));
      }
    },
    deletePreviousImage(imageId) {
      this.deleteMediaFromProduct(imageId)
        .then()
        .catch((err) => console.error(err));
    },
    subcategoriesOfMainCategory(mainCategoryId) {
      return this.paginatedProductSubCategory.collection.filter(
        (subcategory) => subcategory.product_category_id == mainCategoryId
      );
    },
    getMainCategoryFromSubcategory(subcategoryId) {
      var mainCategoryId = null;
      this.paginatedProductSubCategory.collection.map((subCat) => {
        if (subCat.id == subcategoryId) {
          mainCategoryId = subCat.product_category_id;
        }
      });
      return mainCategoryId;
    },
    valuesOfAttribute(attributeId) {
      var values = [];
      this.paginatedAttributes.collection.map((attribute) => {
        if (attribute.id == attributeId) {
          values = attribute.valuesObjects;
        }
      });
      return values;
    },
    getAttributeName(attributeId) {
      var name = "";
      this.paginatedAttributes.collection.map((attribute) => {
        if (attribute.id == attributeId) {
          name = attribute.name;
        }
      });
      return name;
    },
    deleteAttribute(attributeIndex) {
      this.product.attributes.splice(attributeIndex, 1);
    },
    deleteFeature(featureIndex) {
      this.product.features.splice(featureIndex, 1);
    },
    toggleAttributeForm() {
      this.showAttributeForm = !this.showAttributeForm;
    },
    toggleFeatureForm() {
      this.showFeatureForm = !this.showFeatureForm;
    },
    validateThenAddAttribute() {
      if (this.$refs.attributesForm.validate()) {
        this.product.attributes.push({
          attribute_id: this.attribute.id,
          ...this.attribute,
        });
        this.attribute = {};
        this.showAttributeForm = false;
      }
    },
    validateThenAddFeature() {
      if (this.$refs.featuresForm.validate()) {
        this.product.features.push(this.feature);
        this.feature = {};
        this.showFeatureForm = false;
      }
    },
    validateAttributes() {
      if (this.product.attributes.length > 0) {
        return true;
      } else {
        Notify.showMessage(
          "error",
          "Product should at least include one attribute"
        );
        return false;
      }
    },
    validateFeatures() {
      if (this.product.features.length > 0) {
        return true;
      } else {
        Notify.showMessage(
          "error",
          "Product should at least include one feature"
        );
        return false;
      }
    },
    emitSubmission() {
      if (
        this.$refs.form.validate() 
        // && this.validateAttributes() 
        // && this.validateFeatures()
      ) {
        var emittedProduct = {
          ...this.product,
          is_active: this.product.is_active ? 1 : 0,
          is_replaceable: this.product.is_replaceable ? 1 : 0,
          is_cancelable: this.product.is_cancelable ? 1 : 0,
          is_returnable: this.product.is_returnable ? 1 : 0,
          image: this.mainImage,
          images: this.isEdit ? this.product.images : this.images,
        };
        if (typeof emittedProduct.image == "string") {
          emittedProduct.image = null;
        }
        if (this.isEdit) {
          emittedProduct.images = null;
        }
        EventBus.$emit("formSubmitted");
        this.$emit("productSubmitted", emittedProduct);
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
.image {
  height: auto;
  max-width: 150px;
  border: 2px solid var(--v-primary-base);
}
.delete-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}
</style>
