<template>
  <v-select v-on="$listeners" v-bind="$attrs" outlined>
    <template v-slot:item="{ item }">
     <slot name="item" :item="item"></slot>
    </template>
    <template v-slot:selection="{ item }">
      <slot name="selection" :item="item"></slot>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "BaseSelect",
};
</script>

<style scoped>
.v-select {
  max-width: 450px;
}
</style>
