export default class Vendor {
  id;
  amount;
  user;
  user_id;
  created_at;
  constructor(data) {
    this.id = data?.id;
    this.amount = data?.amount;
    this.user = data?.user;
    this.user_id = data?.user_id;
    this.created_at = data?.created_at;
  }
}
