<template>
  <v-data-table
    :headers="headers"
    :items="paginatedItems.collection"
    :items-per-page="paginatedItems.perPage"
    :options.sync="options"
    :server-items-length="paginatedItems.total"
    class="elevation-5 mt-5"
    :expanded.sync="expanded"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <template v-slot:top>
      <v-progress-linear
        v-if="isLoading"
        :indeterminate="true"
        color="accent"
      ></v-progress-linear>
      <v-row align="center">
        <v-col cols="12" :md="filterable ? 11 : 12">
          <v-text-field
            v-if="searchable"
            clearable
            @input="emitDebouncedSearch()"
            v-model="search"
            placeholder="Search...."
            class="px-2"
          >
            <template v-slot:append>
              <v-icon large color="secondary">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col v-if="filterable" cols="12" :md="filterable ? 1 : 0">
          <v-btn icon class="mx-2 text--secondary" @click="toggleFilter">
            <v-icon :color="showFilters? 'secondary': 'primary'" large>mdi-filter</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showFilters" class="mx-2 mt-0">
        <slot name="filters"></slot>
      </v-row>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <slot name="actions" :item="item"></slot>
    </template>
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
      ><slot :name="name" v-bind="slotData"></slot
    ></template>
    <!--row expansion slot-->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <slot name="expansion" :item="item"></slot>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { useGlobalStore } from "@/store/GlobalStore";
import { mapState } from "pinia";

export default {
  name: "BaseDataTable",
  props: {
    headers: Array,
    paginatedItems: Object,
    searchable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useGlobalStore, ["isLoading"]),
  },
  data() {
    return {
      showFilters: false,
      expanded: [],
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: null,
      searchTimeout: null,
    };
  },
  methods: {
    emitDebouncedSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$emit("debouncedSearch", this.search);
      }, 500);
    },
    toggleFilter() {
      this.showFilters = !this.showFilters;
    },
  },
  watch: {
    options() {
      this.$emit("optionsChanged", this.options);
    },
  },
};
</script>
