import AttributeValue from "./AttributeValue";

export default class Attribute {
  id;
  name;
  created_at;
  values = [];
  valuesObjects = [];
  constructor(data) {
    this.id = data?.id;
    this.attribute_id = data?.id;
    this.name = data?.name;
    data?.values?.map((value) => {
      this.values.push(value.value);
      this.valuesObjects.push(new AttributeValue(value));
    });
    this.quantity = data?.quantity;
    this.selected_value = data?.selected_value;
    this.created_at = data?.created_at;
  }
}
