<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedProductSubCategory"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowProductSubCategoryButton :item="item" />
      <EditProductSubCategoryButton :item="item" />
      <DeleteProductSubCategoryButton :item="item" />
    </template>
    <template v-slot:[`item.is_active`]="{ item }">
      <ProductSubCategoryStatusToggler :item="item" />
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ item.created_at | formatDate }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useProductSubCategoriesStore } from "../store/ProductSubCategoriesStore";
import ShowProductSubCategoryButton from "./action_buttons/ShowProductSubCategoryButton.vue";
import EditProductSubCategoryButton from "./action_buttons/EditProductSubCategoryButton.vue";
import DeleteProductSubCategoryButton from "./action_buttons/DeleteProductSubCategoryButton.vue";
import ProductSubCategoryStatusToggler from "./action_buttons/ProductSubCategoryStatusToggler.vue";

export default {
  components: {
    ShowProductSubCategoryButton,
    EditProductSubCategoryButton,
    DeleteProductSubCategoryButton,
    ProductSubCategoryStatusToggler,
  },
  computed: {
    ...mapState(useProductSubCategoriesStore, ["paginatedProductSubCategory"]),
    ...mapWritableState(useProductSubCategoriesStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "name", value: "name" },
        { text: "status", value: "is_active" },
        { text: "created at", value: "created_at" },
        { text: "actions", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useProductSubCategoriesStore, [
      "fetchProductSubCategories",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  mounted() {
    this.fetchProductSubCategories()
      .then()
      .catch((err) => console.error(err));
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>../store/ProductSubcategoriesStore