<template>
    <BaseBannerCategoryForm
      :bannerCategoryObject="bannerCategory"
      :isEdit="true"
      formActionName="Edit banner category"
      @bannerCategorySubmitted="
        (categoryData) => sendCategoryData(categoryData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useBannerCategoriesStore } from "../../store/BannerCategoriesStore";
  
  import BaseBannerCategoryForm from "./BaseBannerCategoryForm.vue";
  
  export default {
    props: {
        bannerCategory: Object,
    },
    methods: {
      ...mapActions(useBannerCategoriesStore, ["editBannerCategory"]),
      sendCategoryData(categoryData) {
        this.editBannerCategory(this.bannerCategory.id, categoryData)
        .then(() => {
          this.$router.push({ name: "banner_categories" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseBannerCategoryForm },
  };
  </script>
  
  <style scoped></style>
  