import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import Product from "./Product";

export default class PaginatedProductCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    if (paginationResponse?.data?.data) {
      paginationResponse?.data?.data?.map((product) => {
        this.collection.push(new Product(product));
      });
    } else {
      paginationResponse?.data?.map((product) => {
        this.collection.push(new Product(product));
      });
    }
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse?.data?.data?.map((product) => {
      this.collection.push(new Product(product));
    });
  }
}
