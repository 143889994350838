import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import WalletsPage from "../pages/WalletsPage.vue";
import WalletPage from "../pages/WalletPage.vue";

export default {
  path: "/wallets",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "wallets",
      component: WalletsPage,
      meta: { title: "Wallets" },
    },
    {
      path: "/wallets/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_wallet",
          component: WalletPage,
          meta: {
            title: "Wallet",
          },
        },
      ],
    },
  ],
};
