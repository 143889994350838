<template>
    <BaseProductCategoryForm
      :productCategoryObject="productCategory"
      :isEdit="true"
      formActionName="Edit product category"
      @productCategorySubmitted="
        (categoryData) => sendCategoryData(categoryData)
      "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useProductCategoriesStore } from "../../store/ProductCategoriesStore";
  
  import BaseProductCategoryForm from "./BaseProductCategoryForm.vue";
  
  export default {
    props: {
        productCategory: Object,
    },
    methods: {
      ...mapActions(useProductCategoriesStore, ["editProductCategory"]),
      sendCategoryData(categoryData) {
        this.editProductCategory(this.productCategory.id, categoryData)
        .then(() => {
          this.$router.push({ name: "product_categories" });
        })
        .catch((err) => console.error(err));
      },
    },
    components: { BaseProductCategoryForm },
  };
  </script>
  
  <style scoped></style>