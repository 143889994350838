<template>
  <BaseDataTable
    :filterable="true"
    :headers="headers"
    :paginatedItems="paginatedBannerCategory"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowBannerCategoryButton :item="item" />
      <EditBannerCategoryButton :item="item" />
      <DeleteBannerCategoryButton :item="item" />
    </template>
    <template v-slot:filters>
      <ActiveFilter />
    </template>
    <template v-slot:[`item.is_active`]="{ item }">
      <BannerCategoryStatusToggler :item="item" />
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ item.created_at | formatDate }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useBannerCategoriesStore } from "../store/BannerCategoriesStore";
import ShowBannerCategoryButton from "./action_buttons/ShowBannerCategoryButton.vue";
import EditBannerCategoryButton from "./action_buttons/EditBannerCategoryButton.vue";
import DeleteBannerCategoryButton from "./action_buttons/DeleteBannerCategoryButton.vue";
import BannerCategoryStatusToggler from "./action_buttons/BannerCategoryStatusToggler.vue";
import ActiveFilter from "./ActiveFilter.vue";

export default {
  components: {
    ShowBannerCategoryButton,
    EditBannerCategoryButton,
    DeleteBannerCategoryButton,
    BannerCategoryStatusToggler,
    ActiveFilter,
  },
  computed: {
    ...mapState(useBannerCategoriesStore, ["paginatedBannerCategory"]),
    ...mapWritableState(useBannerCategoriesStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "type", value: "type" },
        { text: "status", value: "is_active" },
        { text: "created at", value: "created_at" },
        { text: "actions", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useBannerCategoriesStore, [
      "fetchBannerCategories",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  mounted() {
    this.fetchBannerCategories()
      .then()
      .catch((err) => console.error(err));
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>
