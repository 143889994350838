<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedSpaceReservation"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:[`item.vendor_category_id`]="{ item }">
      <RouterLink
        :to="{
          name: 'show_vendor_category',
          params: { id: item.vendor_category_id },
        }"
      >
        {{ item.vendor_category_id }}
      </RouterLink>
    </template>
    <template v-slot:[`item.end_date`]="{ item }">
      {{ item.end_date | formatDate }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useSpaceReservationsStore } from "../store/SpaceReservationsStore";

export default {
  computed: {
    ...mapState(useSpaceReservationsStore, ["paginatedSpaceReservation"]),
    ...mapWritableState(useSpaceReservationsStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "full name", value: "full_name" },
        { text: "phone", value: "phone" },
        { text: "vendor name", value: "vendor_name" },
        { text: "notes", value: "notes" },
        { text: "vendor category id", value: "vendor_category_id" },
        { text: "created at", value: "created_at" },
      ],
    };
  },
  methods: {
    ...mapActions(useSpaceReservationsStore, [
      "fetchSpaceReservations",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  mounted() {
    this.fetchSpaceReservations()
      .then()
      .catch((err) => console.error(err));
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>
