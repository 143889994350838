import BaseService from "@/core/classes/base/BaseService";
import {
  loginAPI,
  logoutAPI,
} from "./Auth.api";
import Mapper from "@/core/classes/mapper/Mapper";
import Client from "@/core/classes/client/Client";

export default class AuthService extends BaseService {
  static login(data) {
    return Client.post(loginAPI, Mapper.camelToUnderscore(data)).then((res) =>
      Promise.resolve(res)
    );
  }
  static logout() {
    return Client.post(logoutAPI).then((res) => Promise.resolve(res));
  }
}
