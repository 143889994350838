<template>
    <v-switch
      :input-value="item.is_active"
      inset
      readonly
      @click="toggleActivation()"
    ></v-switch>
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useProductsStore } from "../../store/ProductsStore";
  
  export default {
    props: {
      item: Object,
    },
    data() {
      return {
        product: this.item,
      };
    },
    methods: {
      ...mapActions(useProductsStore, ["toggleProductActivation"]),
      toggleActivation() {
        // toggle object enable value
        this.product.is_active = this.product.is_active == 0 ? 1 : 0;
        // send editing request with new enable status
        
        this.toggleProductActivation(this.item.id, this.product.is_active)
          .then()
          .catch((err) => console.error(err));
      },
    },
  };
  </script>