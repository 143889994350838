<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> Create new banner </template>
      <v-container>
        <v-row>
          <v-col cols="12">
            <CreateCommissionForm />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import CreateCommissionForm from "../components/forms/CreateCommissionForm.vue";
  
  export default {
    data() {
      return {
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },
          {
            text: "Commission",
            href: "/banners",
            disabled: false,
          },
          {
            text: "Create new banner",
            disabled: true,
          },
        ],
      };
    },
    components: { CreateCommissionForm },
  };
  </script>
  