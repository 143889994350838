<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="start">
        <v-col cols="12" md="9">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="attribute.name"
            label="Name"
            placeholder="attribute name"
            persistent-placeholder
          ></BaseTextField>
          <BaseCombobox
            multiple
            :rules="[requiredRule]"
            v-model="attribute.values"
            :items="attribute.values"
            label="Values"
            placeholder="values"
            persistent-placeholder
          >
          </BaseCombobox>

          <v-row align="center" justify="start">
            <v-btn class="my-5" color="accent" medium @click="emitSubmission()">
              {{ formActionName }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { EventBus } from "@/main";

export default {
  props: {
    attributeObject: Object,
    formActionName: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attribute: this.attributeObject,
    };
  },
  watch: {
    attributeObject: {
      immediate: true,
      handler() {
        this.attribute = this.attributeObject;
      },
    },
    attribute: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  methods: {
    emitSubmission() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        this.$emit("attributeSubmitted", this.attribute);
      }
    },
  },
};
</script>
