<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> Banner Categories </template>
    <template v-slot:tableActions>
      <CreateBannerCategoryButton />
    </template>
    <template v-slot:dataTable>
      <BannerCategoriesTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import BannerCategoriesTable from "../components/BannerCategoriesTable.vue";
import CreateBannerCategoryButton from "../components/action_buttons/CreateBannerCategoryButton.vue";

export default {
  data() {
    return {
      id: this.$route.params.id,
      links: [
        {
          text: "Home",
          href: "/",
          disabled: false,
        },

        {
          text: "Banner categories",
          disabled: true,
        },
      ],
    };
  },
  components: {
    BannerCategoriesTable,
    CreateBannerCategoryButton,
  },
};
</script>

<style></style>
