import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('globalStore', {
  state: () => ({ 
    isLoading: false,
    isDrawerVisible: true
  }),
  getters: {
    getIsLoading: state=>state.isLoading,
    getIsDrawerVisible:state=>state.isDrawerVisible,

  },
  actions: {
    changeLoadingStatus(value){
      this.isLoading=value;
    },
    changeDrawerVisibility(){
      this.isDrawerVisible=!this.isDrawerVisible;
    }
  }
});