import { addNewMediaAPI, deleteMediaAPI } from "./Media.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";

export default class MediaService extends BaseService {
  static addNewMedia(mediaData) {
    return Client.post(addNewMediaAPI, mediaData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) =>
      Promise.resolve(res)
    );
  }
  static deleteMedia(mediaId) {
    return Client.delete(deleteMediaAPI(mediaId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
