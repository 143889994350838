import{
}from '@/core/statics/Permissions'
export const bannerTabs=[
    {
        title:'Banner categories',
        link:'/banner-categories',
    },
    {
        title:'Banners',
        link:'/banners',
    },
];
export const vendorsTabs=[
    {
        title:'Vendor categories',
        link:'/vendor-categories',
    },
    {
        title:'Vendors',
        link:'/vendors',
    },
];
export const productsTabs=[
    {
        title:'Product categories',
        link:'/product-categories',
    },
    {
        title:'Product subcategories',
        link:'/product-subcategories',
    },
    {
        title:'Commissions',
        link:'/commissions',
    },
    {
        title:'Attributes',
        link:'/attributes',
    },
    {
        title:'Products',
        link:'/products',
    },
];
   
export const usersTabs=[
    {
        title:'Users',
        link:'/users',
    },
];
   
export const walletsTabs=[
    {
        title:'Wallets',
        link:'/wallets',
    },
];
export const spacesTabs=[
    {
        title:'Space Reservations',
        link:'/space-reservations',
    },
];
   
