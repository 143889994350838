export const getBannersCategoriesAPI='/banner_categories';
export function getBannersCategoryAPI(categoryId) {
  return "/banner_categories/" + categoryId ;
}
export const addBannerCategoryAPI='/banner_categories';
export function updateBannersCategoryAPI(categoryId) {
  return "/banner_categories/" + categoryId ;
}
export function updateBannersCategoryStatusAPI(categoryId) {
  return "/banner_categories/update-status/" + categoryId ;
}
export function deleteBannersCategoryAPI(categoryId) {
  return "/banner_categories/" + categoryId ;
}