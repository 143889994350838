<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedAttributes "
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowAttributeButton Button :item="item" />
      <EditAttributeButton Button :item="item" />
      <DeleteAttributeButton Button :item="item" />
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ item.created_at | formatDate }}
    </template>
    <template v-slot:[`item.values`]="{ item }">
      {{ item.values.toString() }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useAttributesStore } from "../store/AttributesStore";
import ShowAttributeButton from "./action_buttons/ShowAttributeButton.vue";
import EditAttributeButton from "./action_buttons/EditAttributeButton.vue";
import DeleteAttributeButton from "./action_buttons/DeleteAttributeButton.vue";

export default {
  components: {
    ShowAttributeButton,
    EditAttributeButton,
    DeleteAttributeButton,
  },
  computed: {
    ...mapState(useAttributesStore, ["paginatedAttributes"]),
    ...mapWritableState(useAttributesStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "name", value: "name" },
        { text: "values", value: "values" },
        { text: "created at", value: "created_at" },
        { text: "actions", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useAttributesStore, [
      "fetchAttributes",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  mounted() {
    this.fetchAttributes()
      .then()
      .catch((err) => console.error(err));
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>