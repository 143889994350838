import {
  addCommissionAPI,
  deleteCommissionAPI,
  getCommissionAPI,
  getCommissionsAPI,
  updateCommissionAPI,
} from "./Commissions.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";

export default class CommissionService extends BaseService {
  static getCommissions(params) {
    return Client.get(getCommissionsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getCommission(commissionId) {
    return Client.get(getCommissionAPI(commissionId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addCommission(commissionData) {
    return Client.post(addCommissionAPI, commissionData).then((res) =>
      Promise.resolve(res)
    );
  }
  static updateCommission(commissionId, commissionData) {
    return Client.put(updateCommissionAPI(commissionId), commissionData).then(
      (res) => Promise.resolve(res)
    );
  }
  static deleteCommission(commissionId) {
    return Client.delete(deleteCommissionAPI(commissionId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
