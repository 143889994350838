import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import ProductSubCategoriesPage from "../pages/ProductSubCategoriesPage.vue";
import EditProductSubCategoryPage from "../pages/EditProductSubCategoryPage.vue";
import ProductSubCategoryPage from "../pages/ProductSubCategoryPage.vue";
import CreateProductSubCategoryPage from "../pages/CreateProductSubCategoryPage.vue";

export default {
  path: "/product-subcategories",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "product_subcategories",
      component: ProductSubCategoriesPage,
      meta: { title: "Product subcategories" },
    },
    {
      path: "/product-subcategories/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_product_subcategory",
          component: EditProductSubCategoryPage,
          meta: {
            title: "Edit Product subcategory",
          },
        },
      ],
    },
    {
      path: "/product-subcategories/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_product_subcategory",
          component: ProductSubCategoryPage,
          meta: {
            title: "Product subcategory",
          },
        },
      ],
    },
    {
      path: "/product-subcategories/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_product_subcategory",
          component: CreateProductSubCategoryPage,
          meta: {
            title: "Create Product subcategory",
          },
        },
      ],
    },
  ],
};
