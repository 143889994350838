import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import CommissionsService from "../services/CommissionsService";
import CommissionKeyTransformation from "../models/CommissionKeyTransformation";
import PaginatedCommissionCollection from "../models/PaginatedCommissionCollection";
import Commission from "../models/Commission";

export const useCommissionsStore = defineStore("CommissionsStore", {
  state: () => ({
    paginatedCommission: new PaginatedCommissionCollection(),
    commissionsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    commission: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.commissionsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                CommissionKeyTransformation[this.commissionsTableOptions.sortBy[0]],
              sort_desc: this.commissionsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.commissionsTableOptions.itemsPerPage != -1
            ? this.commissionsTableOptions.itemsPerPage
            : null,
        page:
          this.commissionsTableOptions.itemsPerPage != -1
            ? this.commissionsTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchCommissions();
    },
    setTableOptionsNFetch(options) {
      this.commissionsTableOptions = options;
      this.fetchCommissions();
    },
    resetPaginatedCollection() {
      this.paginatedCommission = new PaginatedCommissionCollection();
    },
    removeCommissionLocally(commissionId) {
      this.paginatedCommission.collection.splice(
        this.paginatedCommission.collection.findIndex(
          (category) => category.id === commissionId
        ),
        1
      );
    },
    fetchCommissions() {
      return BaseStore.promiseHandler(
        () => CommissionsService.getCommissions(this.getFilterSearchSortPageParameters),
        (data) => {
          this.paginatedCommission = new PaginatedCommissionCollection(data);
        }
      );
    },
    createCommission(commissionData) {
      return BaseStore.promiseHandler(() =>
        CommissionsService.addCommission(commissionData)
      );
    },
    editCommission(commissionId, commissionData) {
      return BaseStore.promiseHandler(() =>
        CommissionsService.updateCommission(commissionId, commissionData)
      );
    },
    fetchCommission(commissionId) {
      return BaseStore.promiseHandler(
        () => CommissionsService.getCommission(commissionId),
        (data) => {
          this.commission = new Commission(data.data);
        }
      );
    },
    deleteCommission(commissionId) {
      return BaseStore.promiseHandler(
        () => CommissionsService.deleteCommission(commissionId),
        () => {
          this.removeCommissionLocally(commissionId);
        }
      );
    },
  },
});
