<template>
    <BaseTableItemAction
      v-on="$listeners"
      v-bind="$attrs"
      icon="mdi-pencil"
      actionName="edit"
      :to="{name:'edit_user',params:{id:item.id}}"
    >
      <slot></slot>
    </BaseTableItemAction>
  </template>
  
  <script>
  export default {
    props: {
      item: Object,
    },
    methods: {
    },
  };
  </script>
  