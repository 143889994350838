var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedUsers},on:{"debouncedSearch":function (searchKey) { return _vm.setSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ShowUserButton',{attrs:{"Button":"","item":item}}),_c('EditUserButton',{attrs:{"Button":"","item":item}}),_c('DeleteUserButton',{attrs:{"Button":"","item":item}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [(item.gender == 'male')?_c('v-icon',[_vm._v("mdi-gender-male")]):_c('v-icon',[_vm._v("mdi-gender-female")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.last_login_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.last_login_at))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }