export const getVendorsAPI='/vendors';
export function getVendorAPI(vendorId) {
  return "/vendors/" + vendorId ;
}
export const addVendorAPI='/vendors';
export function updateVendorAPI(vendorId) {
  return "/vendors/" + vendorId ;
}
export function updateVendorStatusAPI(vendorId) {
  return "/vendors/update-status/" + vendorId ;
}
export function deleteVendorAPI(vendorId) {
  return "/vendors/" + vendorId ;
}