<template>
  <BaseTableItemAction
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-delete"
    actionName="delete"
    @click="deleteItem"
  >
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { mapActions } from "pinia";
import { useVendorsStore } from "../../store/VendorsStore";

export default {
  props: {
    item: Object,
  },
  methods: {
    ...mapActions(useVendorsStore,["deleteVendor"]),
    deleteItem(){
      this.deleteVendor(this.item.id);
    },
  },
};
</script>