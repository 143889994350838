<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> Edit commission  </template>
      <v-container>
        <v-row>
          <v-col v-if="!fetchingData" cols="12">
            <EditCommissionForm :commission="commission" />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState, mapActions } from "pinia";
  import { useCommissionsStore } from "../store/CommissionsStore";
  import EditCommissionForm from "../components/forms/EditCommissionForm.vue";
  
  export default {
    mounted() {
      var commissionId = this.$route.params.id;
      this.fetchCommission(commissionId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useCommissionsStore, ["commission"]),
    },
    methods: {
      ...mapActions(useCommissionsStore, ["fetchCommission"]),
    },
    data() {
      return {
        fetchingData:true,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },
          {
            text: "Commissions",
            href: "/commissions",
            disabled: false,
          },
          {
            text: `Edit commission #${this.$route.params.id}`,
            disabled: true,
          },
        ],
      };
    },
    components: { EditCommissionForm },
  };
  </script>