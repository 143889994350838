import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import ProductCategoriesPage from "../pages/ProductCategoriesPage.vue";
import EditProductCategoryPage from "../pages/EditProductCategoryPage.vue";
import ProductCategoryPage from "../pages/ProductCategoryPage.vue";
import CreateProductCategoryPage from "../pages/CreateProductCategoryPage.vue";

export default {
  path: "/product-categories",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "product_categories",
      component: ProductCategoriesPage,
      meta: { title: "Product categories" },
    },
    {
      path: "/product-categories/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_product_category",
          component: EditProductCategoryPage,
          meta: {
            title: "Edit Product category",
          },
        },
      ],
    },
    {
      path: "/product-categories/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_product_category",
          component: ProductCategoryPage,
          meta: {
            title: "Product category",
          },
        },
      ],
    },
    {
      path: "/product-categories/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_product_category",
          component: CreateProductCategoryPage,
          meta: {
            title: "Create Product category",
          },
        },
      ],
    },
  ],
};
