<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="start">
        <v-col cols="12" md="9">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="vendorCategory.name"
            label="Name"
            placeholder="vendor category name"
            persistent-placeholder
          ></BaseTextField>

          <BaseSelect
            :rules="[requiredRule]"
            :items="spaces"
            v-model="vendorCategory.space_id"
            item-value="id"
            chips
            clearable
            label="Space of this vendor category"
            placeholder="choose Space of this vendor category"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.name }}</v-chip>
            </template>
          </BaseSelect>

          <v-row align="center" justify="start">
            <v-btn class="my-5" color="accent" medium @click="emitSubmission()">
              {{ formActionName }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { useVendorCategoriesStore } from "../../store/VendorCategoriesStore";
import { mapActions, mapState } from "pinia";
import { EventBus } from "@/main";

export default {
  props: {
    vendorCategoryObject: Object,
    formActionName: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vendorCategory: this.vendorCategoryObject,
    };
  },
  computed: {
    ...mapState(useVendorCategoriesStore, ["spaces"]),
  },

  watch: {
    vendorCategoryObject: {
      immediate: true,
      handler() {
        this.vendorCategory = this.vendorCategoryObject;
      },
    },
    vendorCategory: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  mounted() {
    this.fetchSpaces()
      .then()
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    ...mapActions(useVendorCategoriesStore, ["fetchSpaces"]),
    emitSubmission() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        this.$emit("vendorCategorySubmitted", this.vendorCategory);
      }
    },
  },
};
</script>
