import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import CommissionsPage from "../pages/CommissionsPage.vue";
import EditCommissionPage from "../pages/EditCommissionPage.vue";
import CommissionPage from "../pages/CommissionPage.vue";
import CreateCommissionPage from "../pages/CreateCommissionPage.vue";

export default {
  path: "/commissions",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "commissions",
      component: CommissionsPage,
      meta: { title: "Commission" },
    },
    {
      path: "/commissions/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_commission",
          component: EditCommissionPage,
          meta: {
            title: "Edit Commission",
          },
        },
      ],
    },
    {
      path: "/commissions/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_commission",
          component: CommissionPage,
          meta: {
            title: "Commission",
          },
        },
      ],
    },
    {
      path: "/commissions/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_commission",
          component: CreateCommissionPage,
          meta: {
            title: "Create Commission",
          },
        },
      ],
    },
  ],
};
