export default class User {
  id;
  first_name;
  last_name;
  email;
  address;
  phone;
  last_login_at;
  gender;
  password;
  status;
  role_id;
  role;
  created_at;
  constructor(data) {
    this.id = data?.id;
    this.first_name = data?.first_name;
    this.last_name = data?.last_name;
    this.address = data?.address;
    this.phone = data?.phone;
    this.email = data?.email;
    this.last_login_at = data?.last_login_at;
    this.gender = data?.gender;
    this.status = data?.status;
    this.role_id = data?.role_id;
    this.role = data?.role;
    this.created_at = data?.created_at;
  }
}
