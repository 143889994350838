import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import SpaceReservationsService from "../services/SpaceReservationsService";
import SpaceReservationKeyTransformation from "../models/SpaceReservationKeyTransformation";
import PaginatedSpaceReservationCollection from "../models/PaginatedSpaceReservationCollection";

export const useSpaceReservationsStore = defineStore("SpaceReservationsStore", {
  state: () => ({
    paginatedSpaceReservation: new PaginatedSpaceReservationCollection(),
    spaceReservationsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    spaceReservation: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.spaceReservationsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                SpaceReservationKeyTransformation[
                  this.spaceReservationsTableOptions.sortBy[0]
                ],
              sort_desc: this.spaceReservationsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.spaceReservationsTableOptions.itemsPerPage != -1
            ? this.spaceReservationsTableOptions.itemsPerPage
            : null,
        page:
          this.spaceReservationsTableOptions.itemsPerPage != -1
            ? this.spaceReservationsTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchSpaceReservations();
    },
    setTableOptionsNFetch(options) {
      this.spaceReservationsTableOptions = options;
      this.fetchSpaceReservations();
    },
    resetPaginatedCollection() {
      this.paginatedSpaceReservation =
        new PaginatedSpaceReservationCollection();
    },
    fetchSpaceReservations() {
      return BaseStore.promiseHandler(
        () =>
          SpaceReservationsService.getSpaceReservations(
            this.getFilterSearchSortPageParameters
          ),
        (data) => {
          this.paginatedSpaceReservation =
            new PaginatedSpaceReservationCollection(data);
        }
      );
    },
  },
});
