import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import Vendor from "./User";

export default class PaginatedVendorCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    if (paginationResponse?.data?.data) {
      paginationResponse?.data?.data?.map((vendor) => {
        this.collection.push(new Vendor(vendor));
      });
    } else {
      paginationResponse?.data?.map((vendor) => {
        this.collection.push(new Vendor(vendor));
      });
    }
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data?.data?.map((vendor) => {
      this.collection.push(new Vendor(vendor));
    });
  }
}
