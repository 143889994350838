import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import ProductCategory from "./ProductCategory";

export default class PaginatedProductCategoryCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    if (paginationResponse?.data?.data) {
      paginationResponse?.data?.data?.map((category) => {
        this.collection.push(new ProductCategory(category));
      });
    } else {
      paginationResponse?.data?.map((category) => {
        this.collection.push(new ProductCategory(category));
      });
    }
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse?.data?.data?.map((category) => {
      this.collection.push(new ProductCategory(category));
    });
  }
}
