import {
  addProductCategoryAPI,
  deleteProductsCategoryAPI,
  getProductCategoriesAPI,
  getProductsCategoryAPI,
  updateProductsCategoryAPI,
  updateProductsCategoryStatusAPI,
} from "./ProductCategories.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class ProductCategoriesService extends BaseService {
  static getProductsCategories(params) {
    return Client.get(getProductCategoriesAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getProductsCategory(categoryId) {
    return Client.get(getProductsCategoryAPI(categoryId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addProductCategory(categoryData) {
    return Client.post(addProductCategoryAPI, categoryData).then((res) =>
      Promise.resolve(res)
    );
  }
  static updateProductsCategory(categoryId, categoryData) {
    return Client.put(updateProductsCategoryAPI(categoryId), categoryData).then(
      (res) => Promise.resolve(res)
    );
  }
  static updateProductsCategoryStatus(categoryId, newStatus) {
    return Client.post(updateProductsCategoryStatusAPI(categoryId), {
      is_active: newStatus,
    }).then((res) => Promise.resolve(res));
  }
  static deleteProductsCategory(categoryId) {
    return Client.delete(deleteProductsCategoryAPI(categoryId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
