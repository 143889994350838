<template>
  <div>
    <div class="my-5">
      <span class="primary--text">name :</span>
      {{ productCategory.name }}
    </div>
    <div class="my-5">
      <v-checkbox
        readonly
        :input-value="productCategory.is_active"
        label="active status"
      ></v-checkbox>
    </div>
    <div class="my-5" v-if="productCategory.sub_categories.length>0">
      <div class="primary--text my-3">Subcategories under this category</div>
      <v-slide-group v-model="currentSubcategory" show-arrows>
        <v-slide-item
          v-for="subcategory in productCategory.sub_categories"
          :key="subcategory.id"
        >
          <v-card
            :to="{
              name: 'show_product_subcategory',
              params: { id: subcategory.id },
            }"
            class="ma-4 px-10 py-5 white--text rounded-pill"
            color="accent"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-scale-transition>
                <v-card-title>
                  {{ subcategory.name }}
                </v-card-title>
              </v-scale-transition>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
    <div class="my-5" v-if="productCategory.products.length>0">
      <div class="primary--text my-3">Products under this category</div>
      <v-slide-group v-model="current" show-arrows>
        <v-slide-item
          v-for="product in productCategory.products"
          :key="product.id"
        >
          <v-card
            :to="{
              name: 'show_product',
              params: { id: product.id },
            }"
            class="ma-4"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-scale-transition>
                <v-card class="mx-auto" max-width="344">
                  <v-img
                    :src="product.image"
                    height="200px"
                  ></v-img>

                  <v-card-subtitle>
                    {{ product.name }}
                  </v-card-subtitle>
                </v-card>
              </v-scale-transition>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productCategory: Object,
  },
  data() {
    return {
      current: null,
      currentSubcategory: null,
    };
  },
};
</script>
