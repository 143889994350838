import Attribute from "@/modules/attributes/models/Attribute";
import Vendor from "@/modules/vendors/models/Vendor";
import Commission from "@/modules/commissions/models/Commission";

export default class Product {
  id;
  name;
  description;
  quantity;
  price;
  attributes = [];
  features = [];
  created_at;
  vendor = new Vendor();
  vendor_id;
  commission = new Commission();
  commission_id;
  commission_value;
  category = {};
  categorizable_id;
  categorizable_type;
  is_active;
  is_returnable;
  is_cancelable;
  is_replaceable;
  image;
  images = [];
  constructor(data) {
    if (data) {
      this.id = data?.id;
      this.name = data?.name;
      this.description = data?.description;
      this.quantity = data?.quantity;
      this.price = data?.price;
      data?.attributes?.map((attribute)=>{
        this.attributes.push(new Attribute(attribute))
      });
      this.features = data?.features;
      this.vendor = new Vendor(data?.vendor);
      this.vendor_id = data?.vendor?.id;
      this.commission = new Commission(data?.commission);
      this.commission_id = data?.commission?.id;
      this.commission_value = data?.commission?.commission_value;
      this.category = data?.category;
      this.categorizable_id = this.category?.id;
      this.categorizable_type =this.category?.type;
      this.is_active = data?.is_active;
      this.is_cancelable = data?.is_cancelable;
      this.is_replaceable = data?.is_replaceable;
      this.is_returnable = data?.is_returnable;
      this.image = data?.image;
      if (data?.images) {
        for (const [key, image] of Object.entries(data?.images)) {
          key;
          this.images.push(image);
        }
      }
      this.created_at = data?.created_at;
    }
  }
}
