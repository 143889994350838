export default class AttributeValue {
  id;
  attribute_id;
  value;
  created_at;
  constructor(data) {
    this.id = data?.id;
    this.value = data?.value;
    this.created_at = data?.created_at;
  }
}
