<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="start">
        <v-col cols="12" md="6">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="user.first_name"
            label="First name"
            placeholder="user first name"
            persistent-placeholder
          ></BaseTextField>
        </v-col>
        <v-col cols="12" md="6">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="user.last_name"
            label="Last name"
            placeholder="user last name"
            persistent-placeholder
          ></BaseTextField>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="12" md="6">
          <BaseTextField
            :rules="[requiredRule,emailRule]"
            v-model="user.email"
            label="Email"
            type="email"
            placeholder="user email address"
            persistent-placeholder
          ></BaseTextField>
        </v-col>
        <v-col cols="12" md="6">
          <BaseTextField
            :rules="[requiredRule,phoneNumberRule]"
            v-model="user.phone"
            label="Phone"
            type="number"
            placeholder="user phone"
            persistent-placeholder
          ></BaseTextField>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="12" md="6">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="user.address"
            label="Address"
            placeholder="user address"
            persistent-placeholder
          ></BaseTextField>
        </v-col>
        <v-col cols="12" md="6">
          <v-radio-group :rules="[requiredRule]"  label="gender : " v-model="user.gender" row>
            <v-radio label="Male" value="male"></v-radio>
            <v-radio label="Female" value="female"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="12" md="6">
          <BaseSelect
            :rules="[requiredRule]"
            :items="roles"
            v-model="user.role_id"
            item-value="id"
            chips
            clearable
            label="Role"
            placeholder="choose user role"
          >
            <template v-slot:item="{ item }">
              {{ item.type }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.type }}</v-chip>
            </template>
          </BaseSelect>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="12" md="6">
          <BasePasswordField
            :rules="isEdit?[]:[requiredRule]"
            v-model="user.password"
            label="Password"
            placeholder="user password"
            persistent-placeholder
          ></BasePasswordField>
        </v-col>
        <v-col cols="12" md="6">
          <BasePasswordField
            v-if="user.password"
            :rules="[requiredRule, confirmPasswordRule(user.password)]"
            v-model="passwordConfirmation"
            label="Password confirmation"
            placeholder="enter password confirmation"
            persistent-placeholder
          ></BasePasswordField>
        </v-col>
      </v-row>

      <v-row align="center" justify="start">
        <v-btn class="my-5" color="accent" medium @click="emitSubmission()">
          {{ formActionName }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions,mapState } from "pinia";
import { useUsersStore } from "../../store/UsersStore";
import { EventBus } from "@/main";

export default {
  props: {
    userObject: Object,
    formActionName: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: this.userObject,
      passwordConfirmation: null,
    };
  },
  watch: {
    userObject: {
      immediate: true,
      handler() {
        this.user = this.userObject;
      },
    },
    user: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  mounted() {
    this.fetchRoles()
      .then()
      .catch((err) => console.error(err));
  },
  computed:{
    ...mapState(useUsersStore,["roles"])
  },
  methods: {
    ...mapActions(useUsersStore,["fetchRoles"]),
    emitSubmission() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        this.$emit("userSubmitted", {...this.user,role_id:1});
      }
    },
  },
};
</script>
