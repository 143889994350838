<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>
        Banners
      </template>
      <template v-slot:tableActions> <CreateBannerButton /> </template>
      <template v-slot:dataTable>
        <BannersTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import BannersTable from "../components/BannersTable.vue";
  import CreateBannerButton from "../components/action_buttons/CreateBannerButton.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },

          {
            text: "Banner",
            disabled: true,
          },
        ],
      };
    },
    components: { BannersTable,CreateBannerButton },
  };
  </script>
  
  <style></style>
  