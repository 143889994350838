<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> Edit product subcategory  </template>
      <v-container>
        <v-row>
          <v-col v-if="!fetchingData" cols="12">
            <EditProductSubCategoryForm :productSubCategory="productSubCategory" />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState, mapActions } from "pinia";
  import { useProductSubCategoriesStore } from "../store/ProductSubCategoriesStore";
  import EditProductSubCategoryForm from "../components/forms/EditProductSubCategoryForm.vue";
  
  export default {
    mounted() {
      var productSubCategoryId = this.$route.params.id;
      this.fetchProductSubCategory(productSubCategoryId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useProductSubCategoriesStore, ["productSubCategory"]),
    },
    methods: {
      ...mapActions(useProductSubCategoriesStore, ["fetchProductSubCategory"]),
    },
    data() {
      return {
        fetchingData:true,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },
          {
            text: "Product subcategories",
            href: "/product-subcategories",
            disabled: false,
          },
          {
            text: `Edit product subcategory #${this.$route.params.id}`,
            disabled: true,
          },
        ],
      };
    },
    components: { EditProductSubCategoryForm },
  };
  </script>