import Product from "@/modules/products/models/Product";
import ProductSubCategory from "@/modules/products_subcategories/models/ProductSubCategory";

export default class ProductCategory {
    id;
    name;
    is_active;
    created_at;
    products=[];
    sub_categories=[];
    constructor(data) {
      this.id = data?.id;
      this.name = data?.name;
      this.is_active = data?.is_active;
      this.created_at = data?.created_at;
      data?.products?.map((product)=>{
        this.products.push(new Product(product))
      });
      data?.sub_categories?.map((subCategory)=>{
        this.sub_categories.push(new ProductSubCategory(subCategory))
      });
    }
  }
  