<template>
  <div>
    <div class="my-5">
      <span class="primary--text">type :</span>
      {{ bannerCategory.type }}
    </div>
    <div class="my-5">
      <v-checkbox
        readonly
        :input-value="bannerCategory.is_active"
        label="active status"
      ></v-checkbox>
    </div>
    <div class="my-5" v-if="bannerCategory.banners.length > 0">
      <div class="primary--text my-3">Banners under this categories</div>
      <v-slide-group v-model="current" show-arrows>
        <v-slide-item v-for="banner in bannerCategory.banners" :key="banner.id">
          <v-card
            :to="{
              name: 'show_banner',
              params: { id: banner.id },
            }"
            class="ma-4"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-scale-transition>
                <v-card class="mx-auto" max-width="344">
                  <v-img
                    v-if="banner.image"
                    :src="banner.image"
                    height="200px"
                  ></v-img>
                  <v-icon v-if="banner.video" x-large> mdi-video </v-icon>
                  <v-card-subtitle>
                    start date: {{ banner.start_date | formatDate }} <br />
                    end date: {{ banner.end_date | formatDate }}
                  </v-card-subtitle>
                </v-card>
              </v-scale-transition>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerCategory: Object,
  },
  data() {
    return {
      current: null,
    };
  },
};
</script>
