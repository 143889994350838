import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import ProductsPage from "../pages/ProductsPage.vue";
import EditProductPage from "../pages/EditProductPage.vue";
import ProductPage from "../pages/ProductPage.vue";
import CreateProductPage from "../pages/CreateProductPage.vue";

export default {
  path: "/products",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "products",
      component: ProductsPage,
      meta: { title: "Products" },
    },
    {
      path: "/products/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_product",
          component: EditProductPage,
          meta: {
            title: "Edit Product",
          },
        },
      ],
    },
    {
      path: "/products/:id/show",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "show_product",
          component: ProductPage,
          meta: {
            title: "Product",
          },
        },
      ],
    },
    {
      path: "/products/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_product",
          component: CreateProductPage,
          meta: {
            title: "Create Product",
          },
        },
      ],
    },
  ],
};
