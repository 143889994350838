<template>
  <div>
    <div class="my-5">
      <span class="primary--text">name :</span>
      {{ attribute.name }}
    </div>
    <div class="my-5">
      <span class="primary--text">values :</span>
      {{ attribute.values.toString() }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attribute: Object,
  },
};
</script>
