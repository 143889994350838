<template>
  <BaseTableItemAction
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-delete"
    actionName="delete"
    @click="deleteItem"
  >
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { mapActions } from "pinia";
import { useAttributesStore } from "../../store/AttributesStore";

export default {
  props: {
    item: Object,
  },
  methods: {
    ...mapActions(useAttributesStore,["deleteAttribute"]),
    deleteItem(){
      this.deleteAttribute(this.item.id);
    },
  },
};
</script>