var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedBanner},on:{"debouncedSearch":function (searchKey) { return _vm.setSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ShowBannerButton',{attrs:{"item":item}}),_c('EditBannerButton',{attrs:{"item":item}}),_c('DeleteBannerButton',{attrs:{"item":item}})]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('BannerStatusToggler',{attrs:{"item":item}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start_date))+" ")]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.end_date))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }