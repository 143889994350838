import { Notify } from "../notify/Notify";

export class BaseError {
  data;//could be validation errors or any meta data of errors
  message ="Unexpected error, please check your internet connection!";//the previewed message for user

  initData(error, data) {
    this.error = error;
    this.data = data;
    return this;
  }
  dispatchError() {
    Notify.showMessage("error",this.message);
  }
}
