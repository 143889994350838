<template>
  <div class="my-10">
    <h1 class="secondary--text">Hello  {{ username?username:'' }}!</h1>
    <p class="primary--text">You can navigate between control items using the side drawer....</p>
  </div>
</template>

<script>
import { useAuthStore } from "@/modules/auth/store";
import { mapState } from "pinia";

export default {
  computed: {
    ...mapState(useAuthStore, ["username"]),
  },
};
</script>
