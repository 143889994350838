<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedUsers"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowUserButton Button :item="item" />
      <EditUserButton Button :item="item" />
      <DeleteUserButton Button :item="item" />
    </template>
    <template v-slot:[`item.name`]="{ item }">
      {{ item.first_name }} {{ item.last_name }}
    </template>
    <template v-slot:[`item.gender`]="{ item }">
      <v-icon v-if="item.gender == 'male'">mdi-gender-male</v-icon>
      <v-icon v-else>mdi-gender-female</v-icon>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ item.created_at | formatDate }}
    </template>
    <template v-slot:[`item.last_login_at`]="{ item }">
      {{ item.last_login_at | formatDate }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUsersStore } from "../store/UsersStore";
import EditUserButton from "./action_buttons/EditUserButton.vue";
import DeleteUserButton from "./action_buttons/DeleteUserButton.vue";
import ShowUserButton from "./action_buttons/ShowUserButton.vue";

export default {
  components: {
    ShowUserButton,
    EditUserButton,
    DeleteUserButton,
  },
  computed: {
    ...mapState(useUsersStore, ["paginatedUsers"]),
    ...mapWritableState(useUsersStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "name", value: "name" },
        { text: "phone", value: "phone" },
        { text: "email", value: "email" },
        { text: "address", value: "address" },
        { text: "gender", value: "gender" },
        { text: "last login at at", value: "last_login_at" },
        { text: "created at", value: "created_at" },
        { text: "actions", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useUsersStore, [
      "fetchUsers",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  mounted() {
    this.fetchUsers()
      .then()
      .catch((err) => console.error(err));
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>
../store/UsersStore
