<template>
  <BaseUserForm
    :userObject="user"
    formActionName="create user"
    @userSubmitted="
      (userData) => sendUserData(userData)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useUsersStore } from "../../store/UsersStore";

import User from "../../models/User";
import BaseUserForm from "./BaseUserForm.vue";

export default {
  data() {
    return {
      user: new User(),
    };
  },
  methods: {
    ...mapActions(useUsersStore, ["createUser"]),
    sendUserData(userData) {
      this.createUser(userData)
        .then(() => {
          this.$router.push({ name: "users" });
        })
        .catch((err) => console.error(err));
    },
  },
  components: { BaseUserForm },
};
</script>

<style scoped></style>
