export default {
  id: "id",
  name: "name",
  address: "address",
  phone: "phone",
  email: "email",
  category: "category",
  is_active: "is_active",
  created_at: "created_at",
  description: "description",
};
