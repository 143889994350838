import {
  addAttributeAPI,
  deleteAttributeAPI,
  getAttributesAPI,
  getAttributeAPI,
  updateAttributeAPI,
} from "./Attributes.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class AttributesService extends BaseService {
  static getAttributes(params) {
    return Client.get(getAttributesAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getAttribute(attributeId) {
    return Client.get(getAttributeAPI(attributeId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addAttribute(attributeData) {
    return Client.post(addAttributeAPI, attributeData).then((res) =>
      Promise.resolve(res)
    );
  }
  static updateAttributes(attributeId, attributeData) {
    return Client.put(updateAttributeAPI(attributeId), attributeData).then(
      (res) => Promise.resolve(res)
    );
  }
  static deleteAttributes(attributeId) {
    return Client.delete(deleteAttributeAPI(attributeId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
