<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> Edit user </template>
      <v-container>
        <v-row>
          <v-col v-if="!fetchingData" cols="12">
            <EditUserForm :user="user" />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState, mapActions } from "pinia";
  import { useUsersStore } from "../store/UsersStore";
  import EditUserForm from "../components/forms/EditUserForm.vue";
  
  export default {
    mounted() {
      var userId = this.$route.params.id;
      this.fetchUser(userId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useUsersStore, ["user"]),
    },
    methods: {
      ...mapActions(useUsersStore, ["fetchUser"]),
    },
    data() {
      return {
        fetchingData:true,
        links: [
          {
            text: "Home",
            href: "/",
            disabled: false,
          },
          {
            text: "Users",
            href: "/users",
            disabled: false,
          },
          {
            text: `Edit user #${this.$route.params.id}`,
            disabled: true,
          },
        ],
      };
    },
    components: { EditUserForm },
  };
  </script>
../store/UsersStore