<template>
  <div>
    <div class="my-5">
      <span class="primary--text">name :</span>
      {{ wallet.name }}
      <v-chip
        :to="{
          name: 'show_wallet_category',
          params: { id: wallet.wallet_category_id },
        }"
        dark
        color="accent"
        class="mx-3"
      >
        category : {{ wallet.wallet_category_name }}</v-chip
      >
    </div>
    <div class="my-5">
      <span class="primary--text">description :</span>
      {{ wallet.description }}
    </div>
    <div class="my-5">
      <span class="primary--text">phone :</span>
      {{ wallet.phone }}
    </div>
    <div class="my-5">
      <span class="primary--text">email :</span>
      {{ wallet.email }}
    </div>
    <div class="my-5">
      <span class="primary--text">created at :</span>
      {{ wallet.created_At | formatDate }}
    </div>
    <div class="my-5">
      <v-checkbox
        readonly
        :input-value="wallet.is_active"
        label="active status"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wallet: Object,
  },
  data() {
    return {
      current: null,
    };
  },
};
</script>
