<template>
  <div>
    <div class="my-5">
      <span class="primary--text">name :</span>
      {{ commission.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    commission: Object,
  },
};
</script>
