import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import VendorsService from "../services/VendorsService";
import MediaService from "../../media/services/MediaService.api";
import VendorKeyTransformation from "../models/VendorKeyTransformation";
import PaginatedVendorCollection from "../models/PaginatedVendorCollection";
import Vendor from "../models/Vendor";

export const useVendorsStore = defineStore("VendorsStore", {
  state: () => ({
    paginatedVendors: new PaginatedVendorCollection(),
    vendorsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    vendor: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.vendorsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                VendorKeyTransformation[this.vendorsTableOptions.sortBy[0]],
              sort_desc: this.vendorsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.vendorsTableOptions.itemsPerPage != -1
            ? this.vendorsTableOptions.itemsPerPage
            : null,
        page:
          this.vendorsTableOptions.itemsPerPage != -1
            ? this.vendorsTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchVendors();
    },
    setTableOptionsNFetch(options) {
      this.vendorsTableOptions = options;
      this.fetchVendors();
    },
    resetPaginatedCollection() {
      this.paginatedVendors = new PaginatedVendorCollection();
    },
    removeVendorLocally(vendorId) {
      this.paginatedVendors.collection.splice(
        this.paginatedVendors.collection.findIndex(
          (vendor) => vendor.id === vendorId
        ),
        1
      );
    },
    toggleVendorActivationLocally(vendorId) {
      this.paginatedVendors.collection.map((vendor) => {
        if (vendor.id == vendorId) vendor.is_active = !vendor.is_active;
      });
    },
    addMediaToVendorLocally(data) {
      this.vendor.images.push(data);
    },
    deleteMediaFromVendorLocally(mediaId) {
      this.vendor.images.splice(
        this.vendor.images.findIndex((image) => image.uuid == mediaId),
        1
      );
    },
    fetchVendors(isPaginated = true) {
      var fetchParams = isPaginated
        ? this.getFilterSearchSortPageParameters
        : {};
      return BaseStore.promiseHandler(
        () => VendorsService.getVendors(fetchParams),
        (data) => {
          this.paginatedVendors = new PaginatedVendorCollection(data);
        }
      );
    },
    createVendor(vendorData) {
      return BaseStore.promiseHandler(() =>
        VendorsService.addVendor(vendorData)
      );
    },
    editVendor(vendorId, vendorData) {
      return BaseStore.promiseHandler(() =>
        VendorsService.updateVendors(vendorId, vendorData)
      );
    },
    fetchVendor(vendorId) {
      return BaseStore.promiseHandler(
        () => VendorsService.getVendor(vendorId),
        (data) => {
          this.vendor = new Vendor(data.data);
        }
      );
    },
    deleteVendor(vendorId) {
      return BaseStore.promiseHandler(
        () => VendorsService.deleteVendors(vendorId),
        () => {
          this.removeVendorLocally(vendorId);
        }
      );
    },
    toggleVendorActivation(vendorId, newStatus) {
      return BaseStore.promiseHandler(
        () => VendorsService.updateVendorsStatus(vendorId, newStatus),
        () => {
          this.toggleVendorActivationLocally(vendorId);
        }
      );
    },
    addMediaToVendor(modelId, file) {
      return BaseStore.promiseHandler(
        () =>
          MediaService.addNewMedia({
            model_id: modelId,
            model_class: "Vendor",
            image: file,
            collection_name: "vendors-images",
          }),
        (data) => {
          this.addMediaToVendorLocally(data);
        }
      );
    },
    deleteMediaFromVendor(mediaId) {
      return BaseStore.promiseHandler(
        () => MediaService.deleteMedia(mediaId),
        () => {
          this.deleteMediaFromVendorLocally(mediaId);
        }
      );
    }
  },
});
