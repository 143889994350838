<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedProducts"
    :show-expand="true"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowProductButton :item="item" />
      <EditProductButton :item="item" />
      <DeleteProductButton Button :item="item" />
    </template>
    <template v-slot:[`item.is_active`]="{ item }">
      <ProductStatusToggler :item="item" />
    </template>
    <template v-slot:expansion="{ item }">
      <div class="pa-5">
        <span class="primary--text">description : </span
        >{{ item.description }}
      </div>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ item.created_at | formatDate }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useProductsStore } from "../store/ProductsStore";
import ShowProductButton from "./action_buttons/ShowProductButton.vue";
import EditProductButton from "./action_buttons/EditProductButton.vue";
import DeleteProductButton from "./action_buttons/DeleteProductButton.vue";
import ProductStatusToggler from "./action_buttons/ProductStatusToggler.vue";

export default {
  components: {
    ShowProductButton,
    EditProductButton,
    DeleteProductButton,
    ProductStatusToggler,
  },
  computed: {
    ...mapState(useProductsStore, ["paginatedProducts"]),
    ...mapWritableState(useProductsStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "name", value: "name" },
        { text: "price", value: "price" },
        { text: "quantity", value: "quantity" },
        { text: "created at", value: "created_at" },
        { text: "status", value: "is_active" },
        { text: "actions", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useProductsStore, [
      "fetchProducts",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  mounted() {
    this.fetchProducts()
      .then()
      .catch((err) => console.error(err));
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>
