<template>
  <div>
    <v-row>
      <v-col align-self="center" cols="12" md="8">
        <div class="my-5">
          <v-chip
            :to="{
              name: 'show_vendor_category',
              params: { id: vendor.vendor_category_id },
            }"
            dark
            color="accent"
          >
            category : {{ vendor.vendor_category_name }}</v-chip
          >
        </div>
        <v-carousel height="350px" class="carousel">
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
            :src="image"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col align-self="center" cols="12" md="4">
        <div class="my-5">
          <span class="primary--text">name :</span>
          {{ vendor.name }}
        </div>
        <div class="my-5">
          <span class="primary--text">description :</span>
          {{ vendor.description }}
        </div>
        <div class="my-5">
          <span class="primary--text">phone :</span>
          {{ vendor.phone }}
        </div>
        <div class="my-5">
          <span class="primary--text">email :</span>
          {{ vendor.email }}
        </div>
        <div class="my-5">
          <span class="primary--text">created at :</span>
          {{ vendor.created_at | formatDate }}
        </div>
        <div class="my-5">
          <v-checkbox
            readonly
            :input-value="vendor.is_active"
            label="active status"
          ></v-checkbox>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    vendor: Object,
  },
  computed: {
    images() {
      var images = [];
      images.push(this.vendor.image);
      this.vendor.images.map((image)=>{
        images.push(image.original_url)
      })
      return images;
    },
  },
};
</script>
