<template>
  <v-file-input v-on="$listeners" v-bind="$attrs" outlined>
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
      ><slot :name="name" v-bind="slotData"></slot
    ></template>
  </v-file-input>
</template>

<script>
export default {
  name: "BaseFileInputField",
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
</style>
