import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import UsersService from "../services/UsersService";
import UserKeyTransformation from "../models/UserKeyTransformation";
import PaginatedUserCollection from "../models/PaginatedUserCollection";
import User from "../models/User";

export const useUsersStore = defineStore("UsersStore", {
  state: () => ({
    paginatedUsers: new PaginatedUserCollection(),
    usersTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    user: null,
    roles:[],
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.usersTableOptions.sortBy.length != 0
          ? {
              sort_by:
                UserKeyTransformation[this.usersTableOptions.sortBy[0]],
              sort_desc: this.usersTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.usersTableOptions.itemsPerPage != -1
            ? this.usersTableOptions.itemsPerPage
            : null,
        page:
          this.usersTableOptions.itemsPerPage != -1
            ? this.usersTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchUsers();
    },
    setTableOptionsNFetch(options) {
      this.usersTableOptions = options;
      this.fetchUsers();
    },
    resetPaginatedCollection() {
      this.paginatedUsers = new PaginatedUserCollection();
    },
    removeUserLocally(userId) {
      this.paginatedUsers.collection.splice(
        this.paginatedUsers.collection.findIndex(
          (user) => user.id === userId
        ),
        1
      );
    },
    fetchUsers(isPaginated = true) {
      var fetchParams = isPaginated
        ? this.getFilterSearchSortPageParameters
        : {};
      return BaseStore.promiseHandler(
        () => UsersService.getUsers(fetchParams),
        (data) => {
          this.paginatedUsers = new PaginatedUserCollection(data);
        }
      );
    },
    fetchRoles() {
      return BaseStore.promiseHandler(
        () => UsersService.getRoles(),
        (data) => {
          //TODO make sure of this
          this.roles = data.data;
        }
      );
    },
    createUser(userData) {
      return BaseStore.promiseHandler(() =>
        UsersService.addUser(userData)
      );
    },
    editUser(userId, userData) {
      return BaseStore.promiseHandler(() =>
        UsersService.updateUsers(userId, userData)
      );
    },
    fetchUser(userId) {
      return BaseStore.promiseHandler(
        () => UsersService.getUser(userId),
        (data) => {
          this.user = new User(data.data);
        }
      );
    },
    deleteUser(userId) {
      return BaseStore.promiseHandler(
        () => UsersService.deleteUsers(userId),
        () => {
          this.removeUserLocally(userId);
        }
      );
    },
  },
});
