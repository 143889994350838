import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import AttributesService from "../services/AttributesService";
import AttributeKeyTransformation from "../models/AttributeKeyTransformation";
import PaginatedAttributeCollection from "../models/PaginatedAttributeCollection";
import Attribute from "../models/Attribute";

export const useAttributesStore = defineStore("AttributesStore", {
  state: () => ({
    paginatedAttributes: new PaginatedAttributeCollection(),
    attributesTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    attribute: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.attributesTableOptions.sortBy.length != 0
          ? {
              sort_by:
                AttributeKeyTransformation[this.attributesTableOptions.sortBy[0]],
              sort_desc: this.attributesTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.attributesTableOptions.itemsPerPage != -1
            ? this.attributesTableOptions.itemsPerPage
            : null,
        page:
          this.attributesTableOptions.itemsPerPage != -1
            ? this.attributesTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchAttributes();
    },
    setTableOptionsNFetch(options) {
      this.attributesTableOptions = options;
      this.fetchAttributes();
    },
    resetPaginatedCollection() {
      this.paginatedAttributes = new PaginatedAttributeCollection();
    },
    removeAttributeLocally(attributeId) {
      this.paginatedAttributes.collection.splice(
        this.paginatedAttributes.collection.findIndex(
          (attribute) => attribute.id === attributeId
        ),
        1
      );
    },
    fetchAttributes(isPaginated = true) {
      var fetchParams = isPaginated
        ? this.getFilterSearchSortPageParameters
        : {};
      return BaseStore.promiseHandler(
        () => AttributesService.getAttributes(fetchParams),
        (data) => {
          this.paginatedAttributes = new PaginatedAttributeCollection(data);
        }
      );
    },
    createAttribute(attributeData) {
      return BaseStore.promiseHandler(() =>
        AttributesService.addAttribute(attributeData)
      );
    },
    editAttribute(attributeId, attributeData) {
      return BaseStore.promiseHandler(() =>
        AttributesService.updateAttributes(attributeId, attributeData)
      );
    },
    fetchAttribute(attributeId) {
      return BaseStore.promiseHandler(
        () => AttributesService.getAttribute(attributeId),
        (data) => {
          this.attribute = new Attribute(data.data);
        }
      );
    },
    deleteAttribute(attributeId) {
      return BaseStore.promiseHandler(
        () => AttributesService.deleteAttributes(attributeId),
        () => {
          this.removeAttributeLocally(attributeId);
        }
      );
    },
  },
});
